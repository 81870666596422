.player-controls
  padding 20px 0 0 0
  height 45px
  -webkit-user-select none
  -moz-user-select none
  -ms-user-select none
  user-select none
  overflow hidden

  .group
    inline-block()
    vertical-align top
    &.basic
      width 145px
      font-size 1px
      line-height 0
      margin-top 4px
      float left
      margin-left 20px
      margin-right 20px

    &.basic,
    &.additional
      justified()
      line-height 0
      margin-top 12px
      > *
        vertical-align top
        cursor pointer
        
    &.additional
      float right
      margin-right 20px
      > *
        margin-left 0.5em

  .prev
    background url(/images/sprite.png) -8px -13px
    width 24px
    height 14px
    cursor pointer
    &:hover
      background url(/images/sprite.png) -8px -53px
    &:active
      background url("/images/sprite.png") -8px -93px

  .next
    background url("/images/sprite.png") -128px -13px
    width 24px
    height 14px
    cursor pointer
    &:hover
      background url("/images/sprite.png") -128px -53px
    &:active
      background url("/images/sprite.png") -128px -93px

  .play,
  .pause
    width 22px
    height 22px

  .play,
  .pause,
  .download
    margin-top -3px
    cursor pointer

  .download
    .dl:not([href]), .inited&
      opacity 0
      filter unquote('alpha(opacity=0)')

  .play
    background: url("/images/sprite.png") -50px -9px

    .playing&
      display none

    &:hover
      background url("/images/sprite.png") -50px -49px
    &:active
      background url("/images/sprite.png") -50px -89px

  .pause
    background url("/images/sprite.png") -90px -10px

    .playing&
      inline-block()

    &:hover
      background url("/images/sprite.png") -90px -50px
    &:active
      background url("/images/sprite.png") -90px -90px

  .track-name
    font-size 1.15em
    margin 0 190px 0 164px
    display block
    overflow hidden
    height 40px
    line-height 36px
    text-overflow ellipsis
    white-space nowrap
    color $header-track-text-color
    vertical-align middle
    @media only screen and (max-width : 515px) and (orientation: portrait)
      display none

    .inited&
      opacity 0
      filter unquote('alpha(opacity=0)')

    @media only screen and (max-width : 600px)
      margin-right 40px

    .track
      opacity 0.8
      filter unquote('alpha(opacity=80)')

    .artist
      opacity 1
      font-size 0.8em
      filter unquote('alpha(opacity=100)')

    .playerImg
      font-size 0
      line-height 0
      display inline-block
      vertical-align middle
      margin-right 5px
      img
        width 40px
        height @width
        border-radius 4px

  .mute
    display block
    cursor pointer
    height 23px
    width 23px
    margin-top -5px
    background url(/images/sprite.png) -330px -8px
    &:hover
      background url(/images/sprite.png) -330px -48px
    &.active
      background url(/images/sprite.png) -330px -88px

  .volume-bar
    margin-top 4px
    cursor pointer
    padding 0
    overflow hidden
    display inline-block !important
    width 100px
    height 4px
    background $volumebar-bg-color
    @media only screen and (max-width : 750px)
      width 60px

    .volume-bar-value
      cursor pointer
      background $volumebar-active-bg-color
      width 90%
      height 7px
      transition width 80ms linear,height 80ms linear

  .repeat
    cursor pointer
    width 22px
    height 25px
    background url("/images/sprite.png") -169px -13px
    &:hover
      background url("/images/sprite.png") -169px -53px
    &.active
      background url("/images/sprite.png") -169px -93px

  .shuffle
    cursor pointer
    width 20px
    height 25px
    background url("/images/sprite.png") -210px -13px
    &:hover
      background url("/images/sprite.png") -210px -53px
    &.active
      background url("/images/sprite.png") -210px -93px

.progress
  position absolute
  width 100%
  top 0
  left 0
  margin-bottom -16px
  height 16px

  .seek-bar
    background #6abada
    overflow hidden
    transition height linear 0.2s
    border-top 1px solid $seekbar-border-color
    top 0
    height 16px

    .currentTime
    .timeLeft
      position fixed
      bottom 50px
      font-size 13px
      color #3b5998
      mix-blend-mode luminosity
      right 10px
      line-height 12px
      pointer-events none
      z-index 9
    .currentTime
      left 10px

    .play-bar
      position absolute
      top 1px
      left 0
      z-index 2
      inline-block()
      height 16px
      background $seekbar-played-bg-color
    .load-bar
      position absolute
      top 1px
      left 0
      z-index 1
      inline-block()
      height 16px
      background $seekbar-load-bg-color

  .handler
    width 16px
    height 16px
    position absolute
    top 0
    border-radius 8px
    background $seekbar-handler-bg-color
    margin-left -8px
    margin-top 1px
    transition margin-top linear 0.2s

.download
  .dl
    display block
    background url("/images/svg/dl_zvuk_active.svg") 50% 50% no-repeat
    width 25px
    height @width

.mobile
  .player-controls
    .volume-bar
      display none !important
    .mute
      display none

@media only screen and (max-width : 400px) and (orientation: portrait)
  .player-controls
    .volume-bar
      display none !important
    .mute
      display none