.search-box
  background $search-bg
  padding 12px 0 0 0
  margin $search-margin
  height $search-height-large
  //overflow hidden
  //padding-right 18px
  border $search-border
  border-radius $search-border-radius
  form
    position relative
    line-height $search-height
    //border $search-form-border
    height $search-height-large
    background $search-form-bg
    border-radius $search-form-border-radius
    //overflow hidden
    .inputSearch
      margin-right $search-button-with + $search-button-margin-left
      height $search-height-large
      background $search-inSearch-bg
      border $search-inSearch-border
      border-top-left-radius 5px
      border-bottom-left-radius 5px
      border-top-right-radius 5px
      border-bottom-right-radius 5px
      //overflow hidden
      if $search-display-select
        select
          display inline-block
          position absolute
          right 185px
          top 0
          height $search-height-large
          width $search-select-width
          background $search-select-bg
          border none
          outline none
          font-size 14px
          -webkit-appearance none
          -moz-appearance none
          appearance none
          color #545454
          pointer-events none
          font-family $font-family
          @media (max-width : 500px)
            width $search-select-collapse-width
      else
        select
          display none
      .inInputSearch
        if $search-display-select
          position relative
          overflow hidden
        else
          margin-right 0
        input
          outline none
          display block
          width 100%
          height $search-height-large
          line-height $search-height
          font-size $search-input-font-size
          font-weight $search-input-font-weight
          padding 0
          margin-left 10px
          border $search-input-border
          background $search-input-bg-color
          text-indent 8px
          color $search-input-text-color
          &::-webkit-input-placeholder {color:#a9a2a2;}
          &::-moz-placeholder          {color:#a9a2a2;}/* Firefox 19+ */
          &:-moz-placeholder           {color:#a9a2a2;}/* Firefox 18- */
          &:-ms-input-placeholder      {color:#a9a2a2;}
          &:focus
            background $search-input-active-bg
            color #000
          &:invalid
            box-shadow none
        input::-webkit-search-decoration,
        input::-webkit-search-cancel-button,
        input::-webkit-search-results-button,
        input::-webkit-search-results-decoration
          display none
        .reset
          position absolute
          top 0
          right 139px
          border none
          display none
          width 30px
          height 37px
          background url('/images/sprite_zvooq.svg') -5px -202px no-repeat
          transition background 200ms
          outline none
          cursor pointer
        input
          &:valid
            & ~ .reset
              display block

      .arrow
        width $search-select-collapse-width
        height $search-height
        position absolute
        display inline-block
        //background-color # 6bbbdb
        background url('/images/sprite_zvooq.svg') -293px -161px no-repeat
        pointer-events none
        right 140px
        top 0
        //right $search-button-with + $search-button-margin-left + $search-button-border-left
        &.arrow_active
          background url('/images/sprite_zvooq.svg') -293px -119px no-repeat
        img
          width 23px
          height 30px
          padding 4px 11px
      .search
        display block
        background: url(/images/search.png) 50% 50% no-repeat;
        background-size 70%
        background-color #6BBCDA
        float right
        width 30px
        height 31px
        padding 3px
        border none
        @media (min-width : 516px)
          display none
        /*@media (max-width : 675px)
          margin 7px*/

    @media only screen and (max-width : 515px)
      button
        display none
      .inputSearch
        margin-right 0
        .inInputSearch
          margin-right 0
        .arrow
          right 37px
        .dropdown
          .dropdownButton
            right 43px

    .dropdown
      .dropdownButton
        width 37px
        height 37px
        background url('/images/sprite_zvooq.svg') -120px -202px no-repeat content-box
        //transition all .5s ease
        position absolute
        color #474747
        right 147px
        top 0
        z-index 9
        cursor pointer
        -webkit-appearance none
        -moz-appearance none
        appearance none
      button
        position absolute
        right 0
        top 0
        cursor pointer
        height $search-height-large
        padding 0
        width $search-button-with
        margin-left $search-button-margin-left
        font-size $search-button-font-size
        font-weight $search-button-font-weight
        line-height 36px
        -webkit-border-top-right-radius 5px
        -webkit-border-bottom-right-radius 5px
        -moz-border-radius-topright 5px
        -moz-border-radius-bottomrigh 5px
        border-top-right-radius 5px
        border-bottom-right-radius 5px
        border $search-button-border
        background $search-button-bg-color
        color $search-button-text-color
        box-sizing initial
        z-index 9

      .select
        cursor pointer
        display block
        padding 12px

    .inputSearch,
    .dropdown-menu
      &.name_focus
        .arrow
          background url('/images/sprite_zvooq.svg') -293px -161px no-repeat
        .arrow_active
          background url('/images/sprite_zvooq.svg') -293px -119px no-repeat
        li.name
          color #0085AC
          &:before
            background url('/images/sprite_zvooq.svg') -125px -168px no-repeat content-box
      &.artist_focus
        .arrow
          background url('/images/sprite_zvooq.svg') -293px -161px no-repeat
        .arrow_active
          background url('/images/sprite_zvooq.svg') -293px -119px no-repeat
        li.artist
          color #0085AC
          &:before
            background url('/images/sprite_zvooq.svg') -164px -169px no-repeat content-box
      &.collections_focus
        .arrow
          background url('/images/sprite_zvooq.svg') -293px -161px no-repeat
        .arrow_active
          background url('/images/sprite_zvooq.svg') -293px -119px no-repeat
        li.collections
          color #0085AC
          &:before
            background url('/images/sprite_zvooq.svg') -325px -168px no-repeat content-box
      &.byRadio_focus
        .arrow
          background url('/images/sprite_zvooq.svg') -293px -161px no-repeat
        .arrow_active
          background url('/images/sprite_zvooq.svg') -293px -119px no-repeat
        li.byRadio
          color #0085AC
          &:before
            background url('/images/sprite_zvooq.svg') -5px -169px no-repeat content-box

    .dropdown-menu
      position absolute
      background-color #fff
      width 181px
      right 0
      top 30px
      font-size 14px
      text-align left
      border none
      border-radius 5px
      border-top-left-radius 5px
      border-top-right-radius 5px
      display none
      z-index 3
      box-shadow 0 5px 4px -1px #5a5a5a
      li:hover
        color #0085AC
        opacity .7
      @media (max-width : 1000px)
        right 0

      li
        padding 0 3px
        //transition all .2s ease-in-out
        cursor pointer
        line-height 30px
        &:first-child
          margin-top 5px
      .name
        &:before
          content ''
          position relative
          display inline-block
          background url('/images/sprite_zvooq.svg') -125px -129px no-repeat content-box
          width 30px
          height 20px
          top 4px
        &:hover:before
          background url('/images/sprite_zvooq.svg') -125px -168px no-repeat content-box
      .artist
        &:before
          content ''
          position relative
          display inline-block
          background url('/images/sprite_zvooq.svg') -164px -130px no-repeat content-box
          width 30px
          height 20px
          top 4px
        &:hover:before
          background url('/images/sprite_zvooq.svg') -164px -169px no-repeat content-box
      .collections
        &:before
          content ''
          position relative
          display inline-block
          background url('/images/sprite_zvooq.svg') -325px -129px no-repeat content-box
          width 30px
          height 20px
          margin -1px auto
          top 4px
        &:hover:before
          background url('/images/sprite_zvooq.svg') -325px -168px no-repeat content-box
      .byRadio
        &:before
          content ''
          position relative
          display inline-block
          background url('/images/sprite_zvooq.svg') -5px -130px no-repeat content-box
          width 30px
          height 20px
          margin -1px auto
          top 4px
        &:hover:before
          background url('/images/sprite_zvooq.svg') -5px -169px no-repeat content-box
      span
        width 100%
        margin-left 5px
        &.active_span
          color #0085AC

.mobile
  .search-box
    height 47px
    padding $search-padding
    form
      height $search-height
      button
        height $search-height
        line-height $search-height
      .inputSearch
        height $search-height
        select
          height $search-height
        .inInputSearch
          input
            height $search-height
            width 62%
        .reset
          right 75px
        .arrow
          img
            padding 8px 11px
      .dropdown
        select
          display none
      .inputSearch,
      .dropdown-menu
        &.name_focus
          .dropdownButton
            background url('/images/sprite_zvooq.svg') -120px -122px no-repeat
        &.artist_focus
          .dropdownButton
            background url('/images/sprite_zvooq.svg') -160px -122px no-repeat
        &.collections_focus
          .dropdownButton
            background url('/images/sprite_zvooq.svg') -322px -121px no-repeat
        &.byRadio_focus
          .dropdownButton
            background url('/images/sprite_zvooq.svg') 0 -123px no-repeat

.tablet
  .search-box
    form
      .dropdown
        select
          display none
      .inputSearch
        .inInputSearch
          input
            width 75%
          .reset
            right 50px