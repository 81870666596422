.iconGenresId6462
  background url(/images/genres/shanson.png) 50% 50% no-repeat
  background-size cover
.iconGenresId6533
  background url(/images/genres/ringtone.png) 50% 50% no-repeat
  background-size cover
.iconGenresId5956
  background url(/images/genres/car_music.png) 50% 50% no-repeat
  background-size cover
.iconGenresId6464
  background url(/images/genres/rus_pop.png) 50% 50% no-repeat
  background-size cover
.iconGenresId6449
  background url(/images/genres/foreign_pop.png) 50% 50% no-repeat
  background-size cover
.iconGenresId6549
  background url(/images/genres/dj_remix.png) 50% 50% no-repeat
  background-size cover
.iconGenresId7629
  background url(/images/genres/club.png) 50% 50% no-repeat
  background-size cover
.iconGenresId7917
  background url(/images/genres/no_voice.png) 50% 50% no-repeat
  background-size cover
.iconGenresId6461
  background url(/images/genres/rusrap.png) 50% 50% no-repeat
  background-size cover
.iconGenresId7129
  background url(/images/genres/classic_mix.png) 50% 50% no-repeat
  background-size cover
.iconGenresId7593
  background url(/images/genres/deep_house.jpg) 50% 50% no-repeat
  background-size cover
.iconGenresId7899
  background url(/images/genres/vocaltrance.png) 50% 50% no-repeat
  background-size cover
.iconGenresId6463
  background url(/images/genres/rus_rock.png) 50% 50% no-repeat
  background-size cover
.iconGenresId7490
  background url(/images/genres/trap.png) 50% 50% no-repeat
  background-size cover
.iconGenresId6460
  background url(/images/genres/foreign_rap.png) 50% 50% no-repeat
.iconGenresId6438
  background url(/images/genres/trance.png) 50% 50% no-repeat
  background-size cover
.iconGenresId7636
  background url(/images/genres/dance.png) 50% 50% no-repeat
.iconGenresId6440
  background url(/images/genres/instrumental.png) 50% 50% no-repeat
  background-size cover
.iconGenresId7531
  background url(/images/genres/SMS_sound.png) 50% 50% no-repeat
  background-size cover
.iconGenresId9678
  background url(/images/genres/arabic_trap.png) 50% 50% no-repeat
  background-size cover
.iconGenresId6435
  background url(/images/genres/classic.png) 50% 50% no-repeat
  background-size cover
.iconGenresId6217
  background url(/images/genres/france_new.png) 50% 50% no-repeat
  background-size cover
.iconGenresId12468
  background url(/images/genres/Russian_deep_house.png) 50% 50% no-repeat
  background-size cover
.iconGenresId11760
  background url(/images/genres/chillout.png) 50% 50% no-repeat
  background-size cover
.iconGenresId6434
  background url(/images/genres/foreign_rock.png) 50% 50% no-repeat
  background-size cover
.iconGenresId6439
  background url(/images/genres/dnb.png) 50% 50% no-repeat
.iconGenresId6436
  background url(/images/genres/dubstep.png) 50% 50% no-repeat
  background-size cover
.iconGenresId8116
  background url(/images/genres/ital_disco.png) 50% 50% no-repeat
  background-size cover
.iconGenresId70788
  background url(/images/genres/Minimal_Techno.png) 50% 50% no-repeat
  background-size cover
.iconGenresId120804
  background url(/images/genres/dream_house.png) 50% 50% no-repeat
  background-size cover
.iconGenresId6437
  background url(/images/genres/dance-house.png) 50% 50% no-repeat
.iconGenresId6451
  background url(/images/genres/disco_electropop.png) 50% 50% no-repeat
.iconGenresId7493
  background url(/images/genres/country.png) 50% 50% no-repeat
  background-size cover
.iconGenresId6444
  background url(/images/genres/instrumental_metal.png) 50% 50% no-repeat
  background-size cover
.iconGenresId8071
  background url(/images/genres/disco.png) 50% 50% no-repeat
  background-size cover
.iconGenresId6543
  background url(/images/genres/soviet_songs.png) 50% 50% no-repeat
  background-size cover
.iconGenresId70789
  background url(/images/genres/Techno.png) 50% 50% no-repeat
  background-size cover
.iconGenresId120744
  background url(/images/genres/electro_house.png) 50% 50% no-repeat
  background-size cover
.iconGenresId128545
  background url(/images/genres/electro_dance.png) 50% 50% no-repeat
.iconGenresId11761
  background url(/images/genres/anime.png) 50% 50% no-repeat
  background-size cover
.iconGenresId12340
  background url(/images/genres/hip-hop.png) 50% 50% no-repeat
  background-size cover
.iconGenresId70790
  background url(/images/genres/rock-n-roll.png) 50% 50% no-repeat
  background-size cover
.iconGenresId12496
  background url(/images/genres/jazz.png) 50% 50% no-repeat
  background-size cover
.iconGenresId10533
  background url(/images/genres/k-pop.png) 50% 50% no-repeat
  background-size cover
.iconGenresId6443
  background url(/images/genres/reggae.png) 50% 50% no-repeat
  background-size cover
.iconGenresId121869
  background url(/images/genres/blues.png) 50% 50% no-repeat
  background-size cover
.iconGenresId9660
  background url(/images/genres/lounge.png) 50% 50% no-repeat
  background-size cover
.iconGenresId6532
  background url(/images/genres/indi_rock.png) 50% 50% no-repeat
.iconGenresId6450
  background url(/images/genres/acoustic_vocal.png) 50% 50% no-repeat
  background-size cover
.iconGenresId6441
  background url(/images/genres/indi_pop.png) 50% 50% no-repeat
  background-size cover
.iconGenresId6442
  background url(/images/genres/alternative.png) 50% 50% no-repeat
  background-size cover
.iconGenresId6544
  background url(/images/genres/author.png) 50% 50% no-repeat
  background-size cover
.iconGenresId12341
  background url(/images/genres/RNB.png) 50% 50% no-repeat
  background-size cover
.iconGenresId71483
  background url(/images/genres/Nu-disco.png) 50% 50% no-repeat
  background-size cover
.iconGenresId12343
  background url(/images/genres/soul.png) 50% 50% no-repeat
.iconGenresId9634
  background url(/images/genres/punk_rock.png) 50% 50% no-repeat
  background-size cover
.iconGenresId120620
  background url(/images/genres/industrial-metal.jpg) 50% 50% no-repeat
  background-size cover
.iconGenresId70486
  background url(/images/genres/hardcore.png) 50% 50% no-repeat
  background-size cover
.iconGenresId70608
  background url(/images/genres/ambient.png) 50% 50% no-repeat
  background-size cover
.iconGenresId-4
  background url(/images/genres/natioanl.png) 50% 50% no-repeat
  background-size cover
.iconGenresId127875
  background url(/images/genres/hard_electro.png) 50% 50% no-repeat
.iconGenresId-2
  background url(/images/genres/gamemusic.png) 50% 50% no-repeat
  background-size cover
.iconGenresId-3
  background url(/images/genres/songs_for_children.png) 50% 50% no-repeat
  background-size cover
.iconGenresId-5
  background url(/images/genres/stub.jpg) 50% 50% no-repeat
  background-size cover
.iconGenresId-8
  background url(/images/genres/sportmusic.png) 50% 50% no-repeat
  background-size cover
.iconGenresId-5
  background url(/images/genres/radiohits.png) 50% 50% no-repeat
  background-size cover
.iconGenresId-6
  background url(/images/genres/seasonmusic.png) 50% 50% no-repeat
  background-size cover
.iconGenresId-7
  background url(/images/genres/celebrationmusic.png) 50% 50% no-repeat
  background-size cover
.iconGenresId-307
  background url(/images/genres/festival.png) 50% 50% no-repeat
  background-size cover
.iconGenresId-306
  background url(/images/genres/standup.png) 50% 50% no-repeat
  background-size cover
.iconGenresId-305
  background url(/images/genres/musical.png) 50% 50% no-repeat
  background-size cover
.iconGenresId-1
  background url(/images/genres/sound_tracks.png) 50% 50% no-repeat