.radio:not(.unstyled)
  text-align justify
  font-size 0
  line-height .1em
  padding $plate-padding
  margin $plate-margin
  clear right
  .clear
    width 100px
  body.mobile &
    padding 0 10px
  @media (max-width : 350px)
    padding 0 15px
  > *
    font-size 1rem
    line-height 1.2em
    display inline-block
    vertical-align top

  &:after
    content ''
    display inline-block
    width 100%

  li
    width 100px

  .this_radio
    max-width 100px
    padding 0
    width 100%
    display inline-block
    background none
    margin $plate-item-margin
    text-align left
    font-size $plate-item-font-size
    font-weight $plate-item-font-weight
    text-decoration $plate-item-text-decoration
    border-radius 5px
    position relative
    box-sizing border-box
    .cover:hover, &.play > cover
      .favorite
        display block
      &:before
        content ''
        position absolute
        top 0
        left 0
        width 100%
        height @width
        background linear-gradient(135deg, rgba(255,255,255,0.4) 0%,rgba(255,255,255,0.589) 37.81%,#3232323d 100%)
        border-radius 5px
    &.collectionGrid
      height 250px
      max-width 200px
      padding 5px
      margin 0 0 30px
      background $plate-item-bg
      &:hover
        background linear-gradient(213.54deg,#02799b .84%,#00c6ff 100.16%);
      .title
        color #fff
        font-size 14px
        padding 8px 5px
      .cover
        img
          border-top-left-radius 5px
          border-top-right-radius 5px
    .tablet &
      margin 14px 0
    body.mobile &
      max-width 40vw
      margin 14px 0
      height calc(40vw + 55px)
      font-size 14px
      &.play
        .ico
          border-radius 100%
          display block
          background url("/images/radio/pause.svg") 50% 50% no-repeat #fff
      &.pause
        .ico
          border-radius 100%
          display block
          background url("/images/radio/play.svg") 50% 50% no-repeat #fff
          background-position-x 10px
      @media(max-width 350px)
        .favorite
          right 36px
          bottom 5px
    a:active
      color inherit

    .cover
      position relative
      display block
      line-height 0
      min-height 100px
      img
        width 100%
        height 100%
        border-radius 5px
      .favorite
        display none
        position absolute
        right 4px
        bottom @right
        width 20px
        height @width
        padding 0
        margin 0
        border none
        min-width unset
        min-height unset
        background url("/images/radio/favorite.png") 50% 50% no-repeat
        &.activated
          display block
          background url("/images/radio/favorite_add.png") 50% 50% no-repeat
        .mobile &
          display block
        @media (hover: hover)
          &:hover
            background url("/images/radio/favorite_add.png") 50% 50% no-repeat

    .title
      display inline-block
      padding 5px 3px
      line-height 1rem
      max-height 45px
      overflow hidden
      text-overflow ellipsis
      max-width 100%
    &.clear
      height 0 !important
      margin 0 !important
      padding 0 !important
      overflow hidden
    .ico
      display none
      position absolute
      background url("/images/radio/play.png") 50% 50% no-repeat
      width 64px
      height 72px
      top 30%
      left 35%
      .mobile &
        background url("/images/radio/play.svg") 50% 50% no-repeat #fff
        background-position-x 10px
        box-shadow 0 3px 5px 1px rgba(0,0,0,0.15);
        border-radius 100%;
        width 32px
        height 30px
        top 38%
        left @top
        @media(orientation landscape)
          top 42%
          left @top
        @media(max-width 750px)
          display block
        @media(max-width 350px)
          top 10%
          left 35%
      .tablet &
        width 70px
        height @width
    .cover:hover
      color #00A9DA
      .ico
        display block
    &.play
      .ico
        display block
        background url("/images/radio/pause.png") 50% 50% no-repeat
      &.loading
        i
          background url('/images/spiner.gif')
          border-radius 11px
    &.pause
      .ico
        display block
        background url("/images/radio/play.png") 50% 50% no-repeat

  &.favoritePage
    text-align unset
    margin 14px 0
    .tablet &
      padding 0 10px
    .mobile &
      text-align justify
      @media(max-width 320px)
        padding 0 5px
    .this_radio
      margin 0 20px 10px 0
      .mobile &
        margin 0
        max-width 100px
        height unset
        font-size 12px
      .ico
        background url("/images/radio/play_hover_sidebar.png") 50% 50% no-repeat
        width 32px
        height 40px
        top 10%
        left 35%
      &.play
        .ico
          display block
          background url("/images/radio/pause_hover_sidebar.png") 50% 50% no-repeat
      &.pause
        .ico
          display block
          background url("/images/radio/play_hover_sidebar.png") 50% 50% no-repeat
      .favorite
        right 29px!important
        bottom 5px

.desktop-sidebar
  .radio
    padding 0
    font-size 0
    li
      width 80px
      overflow hidden
      position relative
      margin 1px 0 0
      .cover
        min-height 79px
    .this_radio
      .ico
        display none
        position absolute
        background url("/images/radio/play_hover_sidebar.png") 50% 50% no-repeat
        width 32px
        height 40px
        top 30%
        left 30%
      &:hover
        .ico
          display block
      &.play
        .ico
          display block
          background url("/images/radio/pause_hover_sidebar.png") 50% 50% no-repeat
      &.pause
        .ico
          display block
          background url("/images/radio/play_hover_sidebar.png") 50% 50% no-repeat
      .favorite
        // right 20px
        bottom 0

.player-controls
  &.radioControll
    .prev
    .next
    .download
    .repeat
    .shuffle
      display none

    .pause
    .play
      margin-left 40px
.progress
  &.radioControll
    display none

.radioLinks
  text-align right
  margin-top -10px
  margin-bottom 20px
  a
    text-decoration underline
    &:hover
      text-decoration none