.societyWidgets
  position relative
  min-height 180px
  .titleVideo
    font-size 13px
    padding 0 5px
    overflow hidden
    max-height 40px
  .youtubeBox
    margin 10px 5px 0
    position relative
    display inline-block
    width calc(100% - 10px)
    span
      font-size 12px
      margin-left 10px
    img, iframe
      width 100%
    &, & img, & iframe
      min-height 151px
    .playButton
      position absolute
      top 50% !important
      left 50% !important
      transform translate3d( -50%, -50%, 0 )
      width 80px
      height 50px
      background-color #333
      box-shadow 0 0 30px rgba( 0,0,0,0.6 )
      z-index 1
      opacity 0.8
      border-radius 6px
      cursor pointer
      &:before
        position absolute
        content ""
        border-style solid
        border-width 15px 0 15px 26.0px
        top 50%
        left 50%
        transform translate3d( -50%, -50%, 0 )
        border-color transparent transparent transparent #fff