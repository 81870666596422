unless EXCLUDE_ZVOOQ
  .songs
    .playOther
      width 27px
      height @width
      background url("/images/svg/playOther.svg") 50% 50% no-repeat
      background-size contain
      background-repeat no-repeat
      margin-top -1px!important
      border none
      &:hover
        opacity .7
      a
        display block
        height 100%
        width 100%

//.item
//  .rbt
//    display none !important
//
//.rbtCont
//  margin-top -7px
//  .rbt:hover
//    margin-top -6px
//
//.rbt
//  background url(/images/rbt/phoneActive.png) 50% 50% no-repeat
//  width 24px
//  height 24px
//  display: inline-block
//  &:hover
//    background #00a9da url(/images/rbt/phoneActive.png) top 50% right 4px no-repeat
//    position relative
//    z-index 10
//    width 104px
//    height 40px
//    margin-left -80px
//    border-radius 0 3px 3px 0
//    overflow hidden
//    &:after
//      content 'Поставить на гудок'
//      position absolute
//      left 10px
//      top 6px
//      width 50px
//      color #fff
//      font-weight bold
//      font-size 12px
//      line-height 14px
//      text-align right

//.mobile
//  .rbt
//    background #00a9da url(/images/rbt/phoneActive.png) top 50% right 4px no-repeat
//    position relative
//    width 104px
//    height 40px
//    border-radius 0 3px 3px 0
//    overflow hidden
//    z-index auto
//    &:hover
//      margin-left 0
//      z-index auto
//    &:after
//      z-index auto
//      content 'Поставить на гудок'
//      white-space normal
//      position absolute
//      left 10px
//      top 6px
//      width 50px
//      color #fff
//      font-weight bold
//      font-size 12px
//      line-height 14px
//      text-align right
//  .actions
//    li
//      vertical-align middle


//.item.active
//  .rbt
//    background #00a9da url(/images/rbt/phoneActive.png) 50% 50% no-repeat
//    &:hover
//      background #00a9da url(/images/rbt/phoneActive.png) top 50% right 4px no-repeat
//
//
//.hide-rbt
//  display none !important
//
//.rbt-offer
//  margin-bottom 20px

//.mobile
//.tablet
//  .item.active
//    .rbt
//      background #00a9da url(/images/rbt/phoneActive.png) top 50% right 4px no-repeat
//      &:hover
//        background #00a9da url(/images/rbt/phoneActive.png) top 50% right 4px no-repeat


:not(.mobile)
:not(.tablet)
  .songs
    .item.itemsPaddingLeft
      overflow visible
      //padding-left 50px
//      margin-left 40px
//      //.rbt:hover
//        //margin-left 0
//        //margin-right -80px

//.rbt-enabled
//  .songs
//    .itemRight
//      display none
//      @media (min-width 480px)
//        display inline-block

//.mobile
//.tablet
//  .itemsPaddingLeft.songs
//    .item
//      padding-left 3px


.songs
  .item noindex
    .phone_but
      background-position center
      background-repeat no-repeat
      background-size 20px
      height 20px
      width 20px
      margin-right 5px
      background-image url("data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMzBweCIgaGVpZ2h0PSIzMHB4IiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNTEyIDUxMiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggc3R5bGU9ImZpbGw6IzRkYThkNzsiIGQ9Ik00NjIuNDksNDY4LjIwNmwtMzMuOTM3LDMzLjkzN2MtNi4wNjMsNi4wMzEtMjMuODEyLDkuODQzLTI0LjM0Myw5Ljg0M2MtMTA3LjQzNSwwLjkwNi0yMTAuODY5LTQxLjI3OS0yODYuODgzLTExNy4zMDlDNDEuMDk2LDMxOC40Ni0xLjEzNywyMTQuNjE5LDAuMDM1LDEwNi44NzJjMC0wLjA2MywzLjg5MS0xNy4zMTIsOS45MzgtMjMuMzEybDMzLjkzNy0zMy45NjhjMTIuNDUzLTEyLjQzNywzNi4yOTUtMTguMDYyLDUyLjk5OC0xMi41bDcuMTU2LDIuNDA2YzE2LjcwMyw1LjU2MiwzNC4xNTUsMjMuOTk5LDM4Ljc4LDQwLjk2N2wxNy4wOTMsNjIuNzE3YzQuNjQsMTctMS41OTQsNDEuMTg2LTE0LjAzMSw1My42MjNsLTIyLjY4NywyMi42ODdjMjIuMjUsODIuNDY3LDg2LjkxOSwxNDcuMTIyLDE2OS4zMzksMTY5LjQwMmwyMi42ODctMjIuNjg3YzEyLjQzOC0xMi40MzgsMzYuNjg3LTE4LjY1Niw1My42ODctMTQuMDMxbDYyLjcxNywxNy4xMjVjMTYuOTM3LDQuNTk0LDM1LjM3NCwyMi4wMyw0MC45NjgsMzguNzQ4bDIuMzc1LDcuMTU2QzQ4MC41NTIsNDMxLjkyNiw0NzQuOTI4LDQ1NS43NjksNDYyLjQ5LDQ2OC4yMDZ6IE0yODcuOTk1LDI1NS45OTNoMzEuOTk5YzAtMzUuMzQzLTI4LjY1NS02My45OTgtNjMuOTk4LTYzLjk5OHYzMS45OTlDMjczLjYzNiwyMjMuOTk0LDI4Ny45OTUsMjM4LjM2OCwyODcuOTk1LDI1NS45OTN6IE00MTUuOTkxLDI1NS45OTNjMC04OC4zNzMtNzEuNjIzLTE1OS45OTYtMTU5Ljk5NS0xNTkuOTk2djMyYzcwLjU5MiwwLDEyNy45OTYsNTcuNDM2LDEyNy45OTYsMTI3Ljk5Nkg0MTUuOTkxeiBNMjU1Ljk5NiwwdjMxLjk5OWMxMjMuNDk2LDAsMjIzLjk5MywxMDAuNDk3LDIyMy45OTMsMjIzLjk5NGgzMS45OTlDNTExLjk4OCwxMTQuNjIyLDM5Ny4zNjcsMCwyNTUuOTk2LDB6Ii8+PC9zdmc+")
      .rbt_button
        width inherit
        height inherit
        display block
  .active
    &.item noindex
      .phone_but
        background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAzMCI+PGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+PHBhdGggZD0iTTI3LjEgMjcuNGwtMiAyYy0uMy40LTEuNC42LTEuNC42QTIzLjUgMjMuNSAwIDAxMCA2LjNzLjItMSAuNi0xLjRsMi0yYy43LS43IDIuMS0xIDMtLjdsLjUuMWE0IDQgMCAwMTIuMyAyLjRsMSAzLjdjLjIgMS0uMSAyLjQtLjkgMy4xTDcuMiAxM2MxLjMgNC44IDUuMSA4LjYgMTAgOS45bDEuMy0xLjNjLjctLjggMi4xLTEuMSAzLjEtLjlsMy43IDFhNCA0IDAgMDEyLjQgMi4zbC4xLjRjLjQgMSAwIDIuNC0uNyAzLjF6TTE2LjkgMTVoMS44YzAtMi0xLjYtMy44LTMuNy0zLjh2MmMxIDAgMS45LjggMS45IDEuOHptNy41IDBjMC01LjItNC4yLTkuNC05LjQtOS40djEuOWM0LjEgMCA3LjUgMy40IDcuNSA3LjVoMS45ek0xNSAwdjEuOWM3LjIgMCAxMy4xIDUuOSAxMy4xIDEzLjFIMzBBMTUgMTUgMCAwMDE1IDB6IiBmaWxsPSIjRkZGRkZFIi8+PC9nPjxkZWZzPjxjbGlwUGF0aCBpZD0iY2xpcDAiPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0wIDBoMzB2MzBIMHoiLz48L2NsaXBQYXRoPjwvZGVmcz48L3N2Zz4=");

.desktop-sidebar
  #banner_auth,
  #banner_zvuk_top
    margin 20px 5px 5px
    cursor pointer

@media(min-width 320px)
  .mobile_rtb_23
    height 220px
    max-height 220px
    overflow hidden

@media(min-width 375px)
  .mobile_rtb_23
    height 250px
    max-height 250px
    overflow hidden

@media(min-width 425px)
  .mobile_rtb_23
    min-height 400px

@media(min-width 768px)
  .mobile_rtb_23
    min-height 600px