.plateItems
  text-align justify
  font-size 0
  line-height .1em
  padding $plate-padding
  margin 14px 0
  clear right
  @media (max-width : 350px)
    padding 0 15px
  > *
    font-size 1rem
    line-height 1.2em
    display inline-block
    vertical-align top
  &:after
    content ''
    display inline-block
    width 100%
  li
    width 100px
  .itemGenre a
    display block

  &.favoritePage
    text-align unset
    @media (max-width 1030px)
      padding 0 10px
    @media (max-width 500px)
      text-align justify
    .item
      margin 0 20px 10px 0
      @media (max-width 500px)
        margin 0
  .item, .itemGenre
    position relative
    width 100%
    max-width 100px
    padding 0
    display inline-block
    background none
    margin $plate-item-margin
    text-align left
    font-size $plate-item-font-size
    font-weight $plate-item-font-weight
    text-decoration $plate-item-text-decoration

    .coverGenre
      position relative
      display block
      //border 2px solid #cceef7
      min-height 79px
      margin-bottom 5px

      i
        position absolute
        width 100%
        height 100%
        border-radius 4px

    .cover
      position relative
      display block
      //border 2px solid #cceef7
      line-height 0

      img
        width 100%
        height 100%
        border-radius 6px
        &:hover
          opacity .8

      //.favoriteIco
      //  position absolute
      //  top 5px
      //  right 5px
      //  height 18px
      //  display inline-block
      //  font-weight bold
      //  font-size 26px
      //  line-height 20px
      //  text-align center
      //  color $link-text-color-hover
      //  opacity 0.8
      //  background #fff
      //  padding 0 5px
      //  border 1px solid #777
      //
      //  &:hover
      //    text-decoration none
      //    opacity 1
      //
      //  &.favoriteAdded
      //    background $link-text-color-hover
      //    color #fff
      //    opacity 1
      //    border 1px solid #fff
      //
      //    &:hover
      //      //color $link-text-color-hover
      //      opacity 1

    .title
      display inline-block
      padding 5px 3px
      line-height 1rem
      max-height 45px
      overflow hidden
      text-overflow ellipsis
      width 100%

    &.clear
      height 0
      margin 0
      overflow hidden

.item
  &.collectionGrid
    height 250px
    max-width 200px
    padding 5px
    // margin 0 0 30px
    background linear-gradient(210.08deg, #43D5FF 0.73%, #007799 100%)
    border-radius 5px
    box-sizing border-box
    a
      display block
      height 100%
    .cover
      img
        &:hover
          opacity 1
    &:hover
      background linear-gradient(213.54deg, #02799B 0.84%, #00C6FF 100.16%)
    .title
      color white
      max-width 190px
      text-overflow ellipsis
      position absolute
      font-style normal
      font-weight 500
      font-size 14px
      line-height 18px
      padding 8px 5px
      white-space normal
      box-sizing border-box
      display inline-block
      max-height 45px
      overflow hidden
      width 100%
  &.collectionGrid
    @media (max-width 390px)
      height 250px
      max-width 200px
      .audio_pl_grid_covers_wrap
        position relative
        height 190px
        width 190px

.audio_pl_grid_covers_wrap
  position relative
  height 190px
  width 190px
  .audio_pl_grid
    top 0
    left 0
    width 190px
    height @width
    box-sizing border-box
    border-bottom-right-radius 0
    border-bottom-left-radius 0
    overflow hidden
    img
      width 95px
      height @width
      border-radius 0
      &:hover
        opacity 1
    .audio_pl_grid_cover_once
      width 100%!important
      height @width
    .audio_pl_grid_cover
      position absolute
      background-size cover
      background-position 50%
    .audio_pl_grid_cover_0
      border-top-left-radius 4px
    .audio_pl_grid_cover_1
      left 50%
      border-top-right-radius 4px
    .audio_pl_grid_cover_2
      top 50%
      border-bottom-left-radius 4px
    .audio_pl_grid_cover_3
      left 50%
      border-bottom-right-radius 4px
      top @left

:not(.mobile)
  .kinetic
    position relative
    margin-top 12px
    &.hidden
      opacity 0

    .kineticRight
    .kineticLeft
      position absolute
      top 32%
      width 30px
      height 30px
      padding 5px
      z-index 10
      background #ffffff
      color #000
      opacity 0
      text-align center
      font-size 15px
      line-height 30px
      cursor pointer
      border-radius 9999px
      box-shadow 0 3px 5px rgba(0,0,0,0.3)
    .kineticRight.zvukKineticRight,
    .kineticLeft.zvukKineticLeft
      top 28%
    .kineticLeft
      left -12px
      padding-right 6px
    .kineticRight
      right -10px
      padding-left 6px

    .kineticCenter
      overflow hidden
      padding 0
      margin -10px 0
    .kineticList
      white-space nowrap
      font-size 0
      position relative
      list-style none
      margin 0
      .collectionGrid
        margin 0
      .newsContainerSlider
        width 200px
        margin-right 20px
        margin-left 1px
        text-align left
        &:last-child
          margin-right 1px
        a
          &:active
            color inherit
            text-decoration none
          .backShadows
            padding 0
            border-radius 6px
            margin-bottom 10px
          img
            max-width 100%
            border-radius 6px
          span
            margin 0
            color #080204
            font-size 15px
            line-height 22px
            min-height 70px
            font-weight 400

      .item + .item
        margin-left 14px


    &:not(.hideArrows) .kineticList
      > *
        white-space normal
    //&:last-child
    //  margin-right 30px
    //&:first-child
    //  margin-left 30px

    &.hideArrows
      .kineticLeft
        display none
      .kineticRight
        display none


    .plateItems
      &:after
        display none

    &.is-scroll-right
      .kineticRight
        opacity 1
        &:hover
          background #efeded
    &.is-scroll-left
      .kineticLeft
        opacity 1
        &:hover
          background #efeded

.mobile
  .kinetic
    margin 10px 0
  .kineticRight
  .kineticLeft
    display none
  .mobile
    .plateItems
      // padding 0 10px
      @media (max-width 320px)
        // padding 0 5px
      .collectionGrid
        height calc(40vw + 55px)
        max-width 40vw
        .title
          max-width calc(40vw - 10px)
        .audio_pl_grid_covers_wrap
          width calc(40vw - 10px)
          height @width
          .audio_pl_grid
            width calc(40vw - 10px)
            height @width
            img
              width calc(20vw - 5px)
              height @width
        .title
          font-size 14px

.desktop-sidebar

  .plateItems
    font-size 0
    padding 0
    margin-bottom 0

    li
      width 80px
      //overflow hidden
      position relative
      margin 5px 0 0

      .cover
        min-height 79px

      a.item
        max-width 83px
        display block
        text-align center
        width 100%
        margin 0

        .title
          display none

        &:hover
          .title
            display block
            position absolute
            top 0
            left 0
            bottom 0
            right 0
            background #cceef7
            color #000
            opacity 0.8
            padding 10px 5px 5px 5px
            max-height initial

.desktop-sidebar
  .plateItems
    &.books
      li
        a.item
          &:hover
            .title
              color transparent

.collectionTop,.genresTop
  overflow hidden
  padding 10px
  .left
    float left
    width 100px
    height @width
    margin-right 10px
    @import "../genres/iconGenres.styl"
    .cover
      width 100%
      height @width
      border-radius 4px
    &.tile
      width 190px
      height @width
      padding 0
      margin-right 20px
      border-radius 5px
      box-sizing border-box
      body.mobile &
        width 100px
        height @width
        padding 2px
        margin-right 10px
        .audio_pl_grid_covers_wrap
          width 96px
          height @width
      &>img
        border-radius 4px
        width 190px
        height 190px
        body.mobile &
          width 96px
          height @width
      .audio_pl_grid_cover_2
        border-bottom-left-radius 4px
      .audio_pl_grid_cover_3
        border-bottom-right-radius 4px

  body.mobile &
    .shared_buttons
      .show_share
        position relative
        min-width 30px
        background-position 50%
        border 2px solid #0085ac
        border-radius 5px
        margin 2px 0 0 8px
        span
          display none
      #share
        top 40px
        padding 5px 0
    h1
      display block
      margin 0 0 20px
      .mobile &
        font-size 20px
        margin-bottom 3px
      .tablet &
        font-size 1.5em
        margin-bottom 20px
  .online
    float left
    margin-left 0

.genresTop
  margin 20px 0 20px 0
  .left
    width 200px
    height @width
    .tablet &
      margin-right 10px
    .mobile &
      @media (orientation landscape)
        margin-right 10px
    @media (max-width 450px)
      width 96px
      height @width
      margin-right 10px
      margin-bottom 10px
      img
        width 96px
        height @width
  .collectionButtonLine
    padding-top 10px
    margin-left 215px
    .mobile &
      margin-left 0
  .main
    margin-left 215px
    .mobile &
      margin-left 110px
    .tablet &
      margin-left 110px
    h1
      margin 0

