.container
  .content
    .desktop
      // todo adnl_zone ??? wtf
      .adnl_zone
        max-width 100% !important
        img
          max-width 100% !important
          height auto !important
    .desktop-sidebar
      .adnl_zone
        overflow hidden
      .closeBanner
        cursor pointer
        display block
        width 24px
        height 24px
        float right
      .closeBanner.show
        background: url("/images/close.png") 0 0 no-repeat
