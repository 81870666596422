@import "logo"
@import "../player/index"

body > .container
  padding-top $header-height

.mobile
  .main_container
    padding-top $header-height*2

.header
  position fixed
  width 100%
  height $header-height
  top 0
  background-color #0085ac //$header-bg-color
  background-position 148% 47px
  z-index 30
  .container
    background transparent //$header-container-bg-color

  #inHeader
    z-index 30
    max-width 660px
    width 100%
    margin-right 35px
    @media (max-width 1030px)
      margin-right 0

  .logo
    max-width 306px
    width 100%
    text-align center
    .tablet &
      width 220px

  .container_header
    height 100%
    max-width ($site-width - 10)
    display flex
    flex-flow row nowrap
    justify-content space-between
    margin 0 auto
    z-index 10000
    .mobile &
      display block

  @media (max-width 1200px)
    #inHeader
      padding-right 15px

.mobile
  #hamburger-icon
    width 35px
    height 40px
    position absolute
    display block
    margin 14px 19px auto

    .line
      display block
      background #ecf0f1
      width 25px
      height 4px
      position absolute
      left 4px
      margin-top 7px
      transition all 0.4s
      -webkit-transition all 0.4s
      -moz-transition all 0.4s

      &.line-1
        top 0

      &.line-2
        top 20%

      &.line-3
        top 40%

    &.active
      .line-1
        transform translateY(10px) translateX(0) rotate(45deg)
        -webkit-transform translateY(10px) translateX(0) rotate(45deg)
        -moz-transform translateY(10px) translateX(0) rotate(45deg)

      .line-2
        opacity 0

      .line-3
        transform translateY(-6px) translateX(0) rotate(45deg * -1)
        -webkit-transform translateY(-6px) translateX(0) rotate(45deg * -1)
        -moz-transform translateY(-6px) translateX(0) rotate(45deg * -1)

  .container_header
    text-align center
    .logo
      width unset
      float none
    #inHeader
      display block
      position fixed
      float none
      width 100%
      max-width unset
      box-sizing border-box
      padding 0 10px
      background-color #036f90
      margin-right 0
      height 60px
      z-index -9
    .myMusic
      position absolute
      display block
      right 0
      top 0
      margin-top 6px
      margin-right 10px