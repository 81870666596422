@charset "utf-8"

@import "../reset"
@import "../settings"
@import "../mixins"
@import "../header/index"
@import "../footer/index"
@import "../feedback/index"
//@import "../popular/index"
@import "../song-items/index"
@import "../pagination"
@import "../responsive/index"
//@import "../modal"
@import "../search/index"
@import "../slider/index"
@import "../radio/index"
@import "../auth/index"
//@import "../popup/index"
@import "../ad/index"

if $specific-search-styl
  @import $specific-search-styl
else
  @import "../search/index"

@import "../menu/index"
@import "../top-menu/index"

@import "../plate/index"

// new
@import "../newmixins"
@import "../ad"
@import "../buttons-box/index"
@import "../genres/index"
@import "../playlist"
@import "../darkTheme"

button, select
  outline none

.hide
  display none !important

html
  height 100%
  background $background-color
  font-family $font-family

body
  font-size $font-size
  line-height $line-height
  color $base-text-color

a
  color $link-text-color
  text-decoration none
  outline none
  &:active
    color #fff
    text-decoration none

.container
  max-width $site-width
  min-width 320px
  margin 0 auto
  padding-bottom 65px
  height 100%
  overflow hidden
  background $container-bg-color

  .content
    z-index 1
    overflow hidden
    margin-bottom 25px
    @media (max-width 500px)
      overflow unset

    .span.desktop
      background $content-bg-color
      @media (min-width 801px)
        margin-left 296px

    .desktop-sidebar
      float left
      width $sidebar-width
      @media (max-width : 800px)
        width 100%
        float none
        margin-right 0
        > *
          display none
        > .mobileShow
          display block
      .societyIcons
        margin-top 20px
        text-align center
        padding 0 12px 0 10px
        line-height 10px
        min-height 37px
        a
          outline none
        img
          cursor pointer
        .society_large
          display block
      @import "../../../../../common/styl/_parts/youtubewidget"
      .societyWidgets
        margin-top 13px
        margin-bottom 15px
        padding 5px
        .backShadows
          min-height 190px
          span
            padding 0 5px
            font-size 12px


.emptyTracks
  p
    display none
    margin 0

.logout
  margin-top 5px
  margin-right 15px
  font-size 15px
  letter-spacing normal
  a
    display flex
    align-items center
    height 50px
    color #fff
    img
      margin-right 5px
      width 24px
      height 24px
  .tablet &
    margin 20px 0 20px 20px

button.filter
  background url("/images/filter.svg") center center no-repeat #0485ac
  display inline-block
  vertical-align middle
  margin -8px 0 0 10px
  width 36px
  height @width
  border none
  outline none
  cursor pointer
  z-index 11
  border-radius 5px
  &:hover,
  &:focus,
  &:active
    opacity: .8
  .mobile &
    margin -2px 0 0 0
  &.active
    @media (min-width 850px)
      position relative
      &:before
        content ''
        position absolute
        background url('/images/globalChoiceArrow.png') 50% 50% no-repeat
        display inline-block
        width 25px
        height 10px
        left 5px
        top 36px

.sort.favoritePage
  .tablet &
  .mobile &
    display inline-block
  .mobile &
    margin-top 5px
    margin-bottom 0
.sort.mobile
  display none
.sort
  display table
  float right
  margin-top 10px
  .mobile &
    margin-top 8px
  .tablet &
    margin-top 3px
  &.favoritePage
    float unset
    display inline-block
    margin-top 0
    margin-bottom 22px
    .mobile &
      padding 0
    a
      height 28px
      line-height 28px
      .mobile &
        padding 3px 10px
        height 20px
        width 18px
        line-height 30px
        &:nth-last-child(-n+2)
          img
            padding-bottom 4px
        img
          vertical-align middle
      .tablet &
        width unset
  a
  span
    &:not(:first-child)
      border-left 1px solid #0086AC
  > *
    display table-cell
    text-align center
    height 34px
    color #fff
    font-size 14px
    line-height 34px
    background-color #5aa0c2
    padding 0 20px
    cursor pointer
    &:first-child
      border-top-left-radius 5px
      border-bottom-left-radius 5px
    &:last-child
      border-top-right-radius 5px
      border-bottom-right-radius 5px
      margin-left -4px
    &:hover
      opacity .8
    &:active
      text-decoration none
    &.activated_sort
      background-color #0485AC
      &:hover
        opacity .8

#globalSelect
  display inline-table
  select
    height 25px
    background-color #0485AC
    background-image url("/images/arrow_white.png")
    background-position 97% 70%
    background-size 19px
    background-repeat no-repeat
    color #fff
    border-radius 3px
    border none
    outline none
    margin 0 15px 10px 0
    width 160px
    padding-left 7px
    font-size 14px
    cursor pointer
    -webkit-appearance none
    -moz-appearance none
    appearance none
    &:hover
      background-color #4EA9C4
    @media (max-width : 500px)
      margin 0 10px 10px 0
      width 140px
      font-size 13px
    .allGenres
      background-color #eee
      color #0085ac
  option
    background-color #fff
    color #545454
    font-size 13px

#globalChoice
  display none
  position absolute
  top 70px
  left 15px
  right 15px
  box-shadow 0 3px 16px rgba(10, 10, 10, 0.32)
  background-color #eaf5fb
  border-radius 7px
  z-index 10
  padding 20px 15px 10px 15px
  box-sizing border-box
  body.mobile &
    top 55px
    max-height 310px
    overflow auto
    @media (orientation : landscape)
      top 85px
  .list
    //padding 20px 20px 10px 20px
    padding-bottom 10px
    margin-bottom 10px
    border-bottom 1px solid #d5e1e8
    column-width 125px
    column-gap 5px
    position relative
    overflow hidden
    line-height 1.6em

    .choice
    .more
    .globalTitle
      color #434546
      cursor pointer
      font-size 14px
      padding 0 4px
      &:hover
        color #5aa0c2
    .choice
      display none
      &.show
        display block
      &.choice_inactive
        pointer-events none
        opacity 0.4
    .choice_active
      background-color #c7deea
      border-radius 3px
      display block
      //cursor pointer
      &:hover
        color unset
    &.opened
      .choice
        display block
      .more
        &> *
          display none
        .hideMore
          display initial
    .more
      display block
      color #2897ce
      .hideMore
        display none
    .globalTitle
      font-weight 700
      color #202020
      display block

  .apply
    width 110px
    height 35px
    border-radius 5px
    margin-right 14px
    background-color #3b84a8
    color #fff
    font-weight 700
    border none
    cursor pointer
    &:hover
      background-color #5aa0c2
  .clear
    border none
    background transparent
    color #3b84a8
    font-weight 700
    cursor pointer
    img
      vertical-align bottom
      padding-right 5px
    &.choice_inactive
      pointer-events none
      opacity 0.4
    .dashed
      vertical-align top

    &.collapsedGenreBoxOpen
    &.collapsedCountryBoxOpen
      max-height initial
      padding-bottom 10px
      .moreGenre:after, .moreCountry:after
        content 'Скрыть'
        height 20px
        bottom -1px
  .dashed
    border-bottom dashed 1px #2897ce
    margin-bottom -1px
  .close
    position absolute
    right 10px
    top 10px
    cursor pointer

.activeTags
  margin-top 10px
.choicesFilter
  position relative
  background-color #d8e6ee
  border-radius 3px
  color #434546
  padding 3px 20px 3px 6px
  margin-right 5px
  @media (max-width 500px)
    display flex
    margin 5px 0 0
  img
    position absolute
    right 5px
    top 8px
    cursor pointer


.mobileShow
  .menu-box
    .society_small
      display none

.tablet
  .mobileShow
    .menu-box
      .line_bottom
        display unset
        position absolute
        top 45px
        left 0
        border-top 2px solid #0486ad
        width 100%
      .society_small
        display block
        padding 20px 0 10px
        line-height 10px
        width 100%
        a
          outline none
        img
          margin-right 5px

.mobile
  .mobileShow
    .menu-box
      .society_small
        display block
        padding 15px 0 0
        a
          outline none
        img
          margin-right 5px
  .topLine
    &.favoritePage
      padding 10px!important
      h1
        font-size 20px
      @media (max-width 350px)
        padding 10px 5px!important
  .emptyTracks
    p
      font-size 14px
      color #818181
      margin 0

.mobile
.tablet
  .container
    .content
      .desktop-sidebar
        width 100%
        float none
        margin-right 0
        > *
          display none
        > .mobileShow
          display block
      .topLine
        &.favoritePage
          padding 10px 15px
      .emptyTracks
        margin 0 10px

  .subTitle
    font-size 1.1em
    max-width 140px
    padding 10px 15px
    margin 0



.tracksHeader div.track-description p
  display none
  margin-top 10px
  color unset
  font-size 16px
  text-align justify
  .mobile &
    display block

.fixedBottom
  position fixed
  bottom 0
  left 0
  width 100%
  background $fixed-bottom-bg
  z-index 1000
  .container
    background $fixed-bottom-container-bg
.box
  overflow hidden
  padding $box-padding
  margin-bottom 20px
  position relative
  @media (max-width : 450px)
    padding 0 10px
  .widgetLink
    &:hover,
    h3:hover
      color #03aeef
      cursor pointer
  &.focus
    padding 10px 5px
    margin-bottom 0
    .module-layout
      .item
        .itemLeft
          .play
            margin 0 5px
        .itemCenter
          display flex
          align-items center
          height 100%
  &.filters
    overflow visible
    h1
      line-height 23px
  .backShadows
    box-shadow 0 0 2px 1px #eeeeee
    border-radius 4px
    padding 10px
    &.song-items-widget
      padding 10px 0
      .titleCollections
        margin 0 10px 10px
    h3
      max-width 140px
      margin 5px 0
  h3
    color $collection-title-color
    margin $box-h-margin
    font-size $box-h-font-size
    font-weight $box-h-font-weight
  &.top
    padding 9px 15px 26px
    margin 0
    body.mobile &
      margin-top 15px
      padding 9px 15px 10px
    p:first-child
      margin-top 0
    .collectionButtonLine
      margin 20px 0 0
      .shared_buttons
        margin 0
        #share
          body.mobile &
            margin-right -5px
    &>h1
      display block
      margin-top 0px
      margin-bottom 12px
  .mobileShow &
    margin-bottom 5px
    h3
      margin-top 9px
  &.widget
    &:first-child
      margin-top 13px
    h3
      font-size 20px
      display inline-block
    .flipping
      display flex
      justify-content center
      align-items center
      margin-top 29px
      .flip
        width 8px
        height @width
        background-color #1797BB
        border-radius 100%
        border 4px solid #FFFFFF
        margin-right 7px
        cursor pointer
        &.active
          background-color #FFFFFF
          border 4px solid #1797BB
  .collectionButtonLine
    @media (min-width 750px)
      .shared_buttons
        margin 2px 5px 0 0
  &.blue
    background linear-gradient(210.08deg, #43D5FF 0.73%, #007799 100%)
    border-radius 4px
    padding 10px
    h1
      line-height 38px
      margin-bottom 12px
      max-width 640px
      color #FFFFFF
      .tablet &
        max-width 530px
      body:not(.mobile) &
        margin-top 4px
    p
      margin-top -10px
      margin-bottom 13px
      color #FFFFFF
      a
        color #FFFFFF
  &.genresTop.blue
    body:not(.mobile) &
      .shared_buttons
        margin 3px 0 0
        .ya-share2__badge
          padding 0
      .button
        &.listen, &.anchor
          background-color #FFFFFF
          border 2px solid #FFF
          color #0180A4
          &.playIco:before
            background-image url(/images/svg/playAlbum_blue.svg)
    body.mobile &
      margin-top 15px
      background none
      .main
        margin-left 0
        margin-top 5px
        h1
          color #000
          line-height 27px
          margin-bottom 4px
      .left
        width calc(100vw - 90px)
        height @width
        background linear-gradient(210.08deg, #43D5FF 0.73%, #007799 100%)
        padding 5px
        margin 0 auto
        margin-bottom 20px
        float initial
        box-sizing border-box
        .cover img
          width calc(100vw - 100px)
          height @width
          max-width 314px
          max-height 314px
          @media (orientation: landscape)
            padding 5px
            width 100px
            height @width
      .collectionButtonLine
        .shared_buttons
          .show_share
            margin 0
          #share
            width calc(100vw - 20px)
        @media (min-width: 360px)
          max-width 340px
          .shared_buttons #share
            min-width 340px
  &.collectionTop.blue
    margin 20px 0
    .widget-right
      display flex
      justify-content space-between
      align-items flex-end
      margin-right 34px
      body:not(.mobile) &
        .shared_buttons
          margin 0 0 4px
          .ya-share2__badge
            padding 0
        .button
          &.favorite
            border 2px solid #FFF
            color #FFFFFF
            .favoriteIco
              sprite2Crop_player(2,1,27,11)
            &.activated
              .favoriteIco
                sprite2Crop_player(2,1,-5,11)
          &.listen, &.anchor
            background-color #FFFFFF
            border 2px solid #FFF
            color #0180A4
            &.playIco:before
              background-image url(/images/svg/playAlbum_blue.svg)

      body &
        .collectionButtonLine.play
          .playIco:before
            background-image url(/images/svg/pause_zvooq_hover.svg) !important
            background-size contain

    body.mobile &
      display flex
      flex-direction column
      background initial
      margin 5px 0 10px
      @media (orientation: landscape)
        display block
      .button.anchor.online
        margin-bottom 10px
        padding 0 6px
        width auto
        margin-right 0
      .left
        width calc(100vw - 90px)
        height @width
        background linear-gradient(210.08deg, #43D5FF 0.73%, #007799 100%)
        padding 5px
        margin 0 auto
        margin-bottom 20px
        &.tile>img
        .audio_pl_grid_covers_wrap
          width calc(100vw - 100px)
          height @width
        @media (orientation: landscape)
          padding 5px
          width 100px
          height @width
          &.tile>img
          .audio_pl_grid_covers_wrap
            width 90px
            height @width
      .main
        margin-left 0
        margin-top 5px
        h1
          color #000
          line-height 27px
          margin-bottom 14px
      .widget-right
        margin 0
        align-items initial
        max-width 340px
        .collectionButtonLine
          display flex
          flex-direction initial
          .button
            margin 0
            margin-right 10px
            height min-content
            &.outline.favorite
              padding 0
              min-width min-content
              &:after
                display none
        .show_share
          margin 0
        .shared_buttons
          #share
            @media (min-width: 360px)
              min-width 340px
      @media (orientation: landscape)
        .shared_buttons
          margin 0
          #share
            padding 0

.zvkNews
  min-height 310px
  .news-container
    a
      span
        display block
        min-height 60px
      &:active
        color inherit
        text-decoration none
    img
      max-width 100%
      width 252px
      margin-bottom 10px
    h4
      margin 0
      color #080204
      font-size 15px
      line-height 22px
      min-height 70px
      font-weight 400
  .arrow-news
    width 60px
    height 60px
    top 40%
    position absolute
    display block
    transition .2s
    cursor pointer
    outline none
    border none
    &:hover
      background #efeded
  .arrow-left
  .arrow-right
    background #ffffff
    text-align center
    width 40px
    height 40px
    font-size 15px
    line-height 30px
    border-radius 9999px
    cursor pointer
    box-shadow 0 3px 5px rgba(0,0,0,0.3)
  .arrow-left
    left 10px
  .arrow-right
    right 10px
  .slide
    -webkit-animation-name fade
    -webkit-animation-duration 1s
    animation-name fade
    animation-duration 1s
    display none
  @-webkit-keyframes fade
    from
      opacity .4
    to
      opacity 1
  @keyframes fade
    from
      opacity .4
    to
      opacity 1

.burger_menu
  margin 5px
  padding 10px
  box-shadow 0 0 2px 1px #eeeeee
  border-radius 4px
  margin-bottom 10px
  .mobile &,
  .tablet &
    margin 0
    padding 0
    border-radius 0
    @media (orientation: landscape)
      max-height calc(100vh - 125px)
      overflow scroll

.taLeft
  text-align left

.taRight
  position absolute
  margin 0
  right 0
  top 50%
  transform translate(0, -50%)
  .moreButton
    display inline-block
    background #0485ac
    padding 5px 10px
    color #fff
    border none
    line-height 16px
    font-size 14px
    float right
    border-radius 5px
    &:hover
      background #4797be
    @media (max-width 350px)
      .widget &
        position absolute
        right 10px
        top 10px

.titleCollections,
.titleGenres
  position relative
  margin-bottom 10px

.taCenter
  text-align center

.widgetHeader
  display flex
  align-items center
  justify-content space-between
  .moreButton
    .widget &
      display inline-block
      background #0485ac
      min-width 100px
      text-align center
      color #fff
      border none
      padding 0 7px
      line-height 25px
      font-size 14px
      font-weight 500
      border-radius 5px
      &:hover
        background #4797be

.mobile
  .mobileHide
    display none !important
  .sort
    display none
  .sort.mobile
    display table
    >*
      display table-cell
      text-align center
      height 34px
      padding 0 5px
      font-size 14px
      line-height 33px
      font-weight 700
      text-transform uppercase
      img
        vertical-align middle
        margin 0 5px
        width 18px
        height 18px

p
  margin 10px 0

h1
  display inline-block
  font-size 1.6em
  font-weight bold
  line-height normal

.button
  background #0085ac
  color #fff
  display inline-block
  margin 0 7px
  padding 2px 6px
  text-align center
  border-radius 5px
  text-decoration none
  cursor pointer
  min-width 140px
  min-height 30px
  line-height 26px
  border 2px solid #0085ac
  box-sizing border-box
  &:hover
    background #4797be
    border 2px solid #4797be
  &.button_more
    display inline-block !important
    min-width 125px
  &:active
    text-decoration none
    opacity 0.8
  &.outline
    background inherit
    color black
    &:after
      content 'Добавить в Мою Музыку'
  &.outline.activated
    background @background
    color @color
    min-width 210px
    &:after
      content 'Убрать из моей музыки'

.linkColor
  text-decoration underline
  color #0085ac
  &:hover
    text-decoration none
  &:active
    color inherit
  img
    vertical-align middle
    margin-left 5px

.topAvatar
  vertical-align top
  img
    margin-top 6px
    border-radius 25px

.ajaxContentLoaded
  &:after
    content ''
    display block
    height 20px
    margin auto
    border-radius 15px
    background  url('/images/spiner_ajax.gif') 50% 50% no-repeat
  .dark_theme &
    &:after
      background  url('/images/spiner-dark.gif') 50% 50% no-repeat

.searchTitleLine
  margin-top 15px
  margin-right 0
  overflow auto
  @media (max-width 450px)
    margin 15px 10px 10px
    .show_share
      margin-right 0
  h1
    float left
    overflow-wrap break-word
    word-wrap break-word
    max-width 600px
    margin 2px 0 0 10px
    &:first-letter
      text-transform uppercase
    &.tablet
      max-width 510px
    @media (max-width 450px)
      margin 0 0 10px
      max-width 96%
      max-width calc(100% - 10px)//для поддержки до css3

.shared_buttons
  float right
  display flex
  margin 2px 5px 20px 0
  position relative
  height 30px
  align-items center
  p
    display none

  .show_share
    background-image url("/images/shared_blue.svg")
    color #309ebd
    background-color #fff
    background-repeat no-repeat
    background-position 94% center
    opacity 1
    display none
    border 1px solid #0085ac
    min-width 126px
    @media(min-width 340px)
      min-width 140px
    &.active
      background-image url("/images/shared_white.svg")
      background-color #0085ac
      color #fff
      margin-bottom 8px
    span
      padding-right 25px

  @media (min-width 550px)
    .ya-share2__badge
      padding 3px

  &.rollUp
    height unset
    @media (max-width 550px)
      margin 0
      display unset
      @media(max-width 350px)
        margin-left 5px
      p
        display none
      #share
        position absolute
        float right
        right 0
        display none
        max-width 310px
        margin-right 7px
        white-space nowrap
        @media (max-width 500px)
          margin-right 0

      .show_share
        display block

  @media (min-width 551px)
    .inited#share
      display block !important

  .support
    display none
    height 40px
    margin-bottom 5px

  @media (max-width 500px)
    display unset
    margin 0 0 20px
    p
      display none
    &.rollUp
      #share
        min-width calc(100vw - 20px)
    .ya-share2__container_size_m
      .ya-share2__list
        display flex
        justify-content space-between
        &.ya-share2__list_direction_horizontal
          margin-top -3px
        .ya-share2__icon
          width 30px
          height @width
          background-size unset

.collectionButtonLine,
.left ~ &
  margin-top 10px
.listen,
.online
  min-width 167px
  @media (max-width 330px)
    min-width 150px

.collectionButtonLine
  .collectionTop &
    display flex
    flex-direction column
    float left
  .left~ &
    .collectionTop &
      margin-left 0
      .tablet &
        display flex
        .button.outline
          max-width 230px
  .button
    margin-left 0
    margin-right 0
    padding 0 6px
    &.listen.playIco, &.anchor.online
      display inline-flex
      align-items center
      width max-content
      min-width auto
      margin-bottom 15px
      padding 0 15px
      font-size 15px
      .genresTop &
        margin-bottom 0
      &:before
        content ''
        display inline-block
        background-image url(/images/svg/playAlbum.svg)
        height 16px
        width 15px
        margin-right 8px
    &.favorite
      color #0180A4
      font-size 15px
      padding 0 13px 0 6px
      .favoriteIco
        margin-right 0
      &.activated
        color #FFFFFF
  @media (max-width 500px)
    margin-left 0
    margin-top 0
    clear both
    .left ~ &//иначе селектор выше перекрывает специализацию этого
      margin-left 0
      margin-top 0
      clear both
    .shared_buttons #share
      margin-right 0
    .favorite
      margin-bottom 5px
      min-width 230px

.collectionButtonLineRadio
  overflow hidden
  min-width 232px
  .button.outline.favorite
    margin 2px 0
    .dark_theme &
      color #fff
  .collectionButtonLine
    margin-left 0
  @media (max-width 750px)
    clear both
    margin-left 0
    margin-top 0
    .left ~ &//иначе селектор выше перекрывает специализацию этого
      margin-left 0
      margin-top 0

.smallMargin
  .box
    margin 0


// branding
body.branding
  @media only screen and (min-width : 768px)
    padding-top: 280px;
  //.admulti-bg-banner a
  //  top 65px !important
  //height: auto !important;
  //position: relative !important;
  background-position-y 62px !important
  //&:not(.vk-download)
  //  .skin-block
  //    background-position center 60px !important
  //.container
  //  overflow hidden
  //  .content
  //    //background: url(/images/bg.png) #4c4c4c!important;
  //    background #fff !important
  //    position: relative !important;
  //    margin-top: 285px !important;
  //    @media (min-width 600px)
  //      //background #fff !important
  //      margin-top: 385px !important;
  //.footer
  //  position relative
  //  z-index 1
  >.container
    //margin-top 360px
    max-width 1200px
    .content
      margin-top 5px




//body.branding
//  &:not(.vk-download)
//    .skin-block
//      background-position center 60px !important
//    .container
//      .content
//        margin-top: 200px !important;
//        @media (min-width 600px)
//          margin-top: 385px !important;
//  &.vk-download
//    .skin-block
//      background-position center 110px !important
//    .container
//      .content
//        margin-top: 240px !important;
//        @media (min-width 600px)
//          margin-top: 385px !important;

.messageContainer
  color: black;
  position: absolute;
  display: flex;
  text-align: center;
  width: 100%;
  left: 0;
  bottom: 100%;
  z-index: 50;
  .message
    background: white;
    border: black solid 1px;
    padding: 2px 5px;
    margin: 0 auto;

.topPageDescription
  padding 0 15px

.pageDescriptionMore
  position relative
  .pageDescriptionMoreButton
    display none
    background: linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 85%,rgba(255,255,255,0) 100%)
    height 37px
    width 100%
    bottom 0
    position absolute

.mobile .pageDescriptionMore
  color #000
  height 115px
  margin-bottom 20px
  display -webkit-box
  text-overflow ellipsis
  overflow hidden
  -webkit-line-clamp 4
  -webkit-box-orient vertical
  p
    color #000
    margin 0 0 20px 0
  &.showMore
    height 100%
    text-overflow initial
    display block
    .pageDescriptionMoreButton {
      display none
    }
.mobile .tracksHeader .pageDescriptionMore
  height 115px
  margin-top 20px
  margin-bottom 0
  &.showMore
    height 100%

.mobile .pageDescriptionMoreButton
  display flex
  align-items flex-end
  &:before
    content "Читать ещё"
    text-decoration underline
    color #0485AC
  &:hover,
  &:focus,
  &:active
    cursor pointer
    &:before
      text-decoration none

.topPageDescription
.genresTop .main
  a
    text-decoration underline

.headlineLine
  margin-top 5px
  min-height 50px
  .filterByCategory
    padding 0
    margin 0
    float right
  h1
    display inline-block
    .mobile &
      font-size 20px
      max-width 60%
  > *
    margin-bottom 0

.topLine
  &.favoritePage
    .favorite_header
      display flex
      justify-content space-between
      align-items center
      .darkTheme
        span
          color #000

.standUpWrapper
  h1
    margin-left 15px

.favoriteIco
  width 30px
  height 25px
  margin 0
  sprite2Crop_player(0,1,6,11)
  &.activated
  .activated &
    sprite2Crop_player(1,1,15,11)
  .button.activated &
    sprite2Crop_player(2,1,-6,11)
  .active &
    sprite2Crop_player(1,1,-17,11)
    &.activated
    .activated &
      sprite2Crop_player(2,1,-7,11)

  .button &
    display inline-block
    float left

/* Кнопка "Показать ещё" */
.btn_ajax
  display flex
  justify-content center
  align-items center
  width 99%
  height 30px
  background #fff
  color #0485AC
  border 2px solid #0485AC
  border-radius 5px
  font-size 15px
  font-weight 500
  line-height 19px
  text-align center
  transition .5s
  user-select none
  cursor pointer
  &:hover
    background #0485ac
    color #fff

.btn_ajax[disabled="disabled"]
  opacity .6

.mainSongs + .btn_ajax
  width auto
  margin 20px 20px 0 10px

.module-layout .ajaxSection_content.songs .item:last-of-type
  border-bottom 1px solid #f2f2f2

.mobile .ajaxSection .plateItems li.item.clear,
.mobile .ajaxSection .radio li.item.clear
  display none