ul.genres-box
  display flex
  flex-wrap wrap
  justify-content space-between
  .mobile &
    padding 10px
  .tablet &
    padding-top 0
  .item
    max-width 200px
    padding 0
    margin 15px 0 10px 0
    background linear-gradient(210.08deg,#43d5ff .73%,#079)
    border-radius 5px
    font-size $plate-item-font-size
    font-weight $plate-item-font-weight
    .tablet &
      margin 15px 5px 10px 0
    @media (max-width 450px)
      width 165px
      margin 0 0 30px
    @media (max-width 375px)
      width 150px
    @media (max-width 320px)
      width 128px
    a
      width 200px
      height 260px
      @media (max-width 450px)
        width 165px
        height calc(40vw + 55px)
      @media (max-width 375px)
        width 150px
      @media (max-width 320px)
        width 128px
    .cover
      height 200px
      width @height
      filter drop-shadow(0 0 5px rgba(0, 0, 0, .5))
      img
        padding 5px
        width 190px
      @media (max-width 450px)
        width 155px
        height @width
        img
          width 155px
          height @width
      @media (max-width 375px)
        width 140px
        height @width
        img
          width 140px
          height @width
      @media (max-width 320px)
        width 118px
        height @width
        img
          width 118px
          height @width

    .title
      display inline-block
      margin 10px
      max-height 35px
      width 90%
      overflow hidden
      text-overflow ellipsis
      font-weight 500
      font-size 14px
      line-height 18px
      color #fff
      .mobile &
        margin 10px
      @media (max-width 320px)
        width 80%

    &.clear
      height 0
      margin 0
      overflow hidden

  li
    overflow hidden
    display inline-block
    width 30%
    a
      letter-spacing 0.2px
      display block
      height 135px
      line-height 50px
      text-decoration $button-box-item-text-decoration
      opacity $button-box-item-opacity
      background transparent
      color $button-box-item-text-color
      border $button-box-item-border
      &:hover
        opacity $button-box-item-hover-opacity
        background transparent
        color #00aeef
        border $button-box-item-hover-border
        text-decoration $button-box-item-hover-text-decoration

    i
      width 50px
      height 50px
      display inline-block
      vertical-align middle

.desktop-sidebar

  ul.genres-box
    li
      display block
      width auto