.feedback-main-container
  position fixed
  box-sizing border-box
  bottom 88px
  right 36px
  z-index 9999
  .feedback-button
    margin-bottom -20px
    cursor pointer

.feedback-modal
  position relative
  display none
  align-items center
  flex-direction column
  padding 20px
  width 335px
  min-height 510px
  transition .2s ease-in
  border-radius 10px
  background #FFFFFF
  box-shadow 0 2px 10px rgba(0, 0, 0, 0.25)

  h3
    width 335px
    font-size 20px
    font-weight bold
    line-height 26px
    text-align center
    margin 6px 0
  p
    &.top-text
      width 335px
      margin-top 48px
      margin-bottom 15px
      font-size 16px
      font-weight bold
      line-height 20px
      text-align center

  form
    span
      &.form-title
        font-size 18px
        font-weight bold
        line-height 26px
        text-align center
        margin 10px 0
      &.error
        width 335px
        padding 0 10px
        text-align center
        color tomato

  .feedback-logo
    margin 20px 0

  .cross
    position absolute
    right 20px
    height 14px
    cursor pointer
    img
      width 14px
      height @width

  form
    display flex
    justify-content center
    flex-direction column

    .rating-star
      overflow hidden
      width 233px
      margin 10px auto

    .rating-star:not(:checked) > input
      display none

    .rating-star:not(:checked) > label
      display block
      width 32px
      height 30px
      float right
      margin-left 18px
      padding 0
      background-image url("/images/svg/raiting_star.svg")
      background-repeat no-repeat
      transition .2s ease-in
      cursor pointer
      &:last-child
        margin-left 0

    .rating-star > input:checked ~ label
      background-image url("/images/svg/raiting_star_active.svg")
      background-repeat no-repeat

    .rating-star:not(:checked) > label:hover,
    .rating-star:not(:checked) > label:hover ~ label
      background-image url("/images/svg/raiting_star_active.svg")
      background-repeat no-repeat

    .rating-star > input:checked + label:hover,
    .rating-star > input:checked + label:hover ~ label,
    .rating-star > input:checked ~ label:hover,
    .rating-star > input:checked ~ label:hover ~ label,
    .rating-star > label:hover ~ input:checked ~ label
      background-image url("/images/svg/raiting_star_active.svg")
      background-repeat no-repeat

    textarea
      overflow auto
      resize none
      width 335px
      height 92px
      padding 12px
      margin 5px 10px
      border 1px solid #B7B7B7
      border-radius 10px
      color #000
      font-size 16px
      font-weight 500
      line-height 20px
      text-align center
      box-sizing border-box
      opacity 0.6
      &:focus
        outline -webkit-focus-ring-color 0

    .submit
      width 335px
      height 55px
      cursor pointer
      background #0180A4
      color #FFFFFF
      font-weight 700
      font-size 24px
      align-self center
      border none
      border-radius 5px
      margin-top 10px

    .submit:focus
      outline none

.feedback-button-lk
  float right
  cursor pointer

.tablet
.mobile
  .feedback-button-lk
    margin-top 5px

.mobile
  .feedback-main-container
    right 0
    bottom 62px
    @media only screen and (min-device-width 320px) and (max-device-width 1030px) and (orientation landscape)
      display none
    .feedback-button
      margin-bottom 0
    .feedback-modal
      @media (max-width 550px)
        width 100vw
        box-sizing border-box
        max-height calc(100vh - 50px)
        overflow-y auto
        overflow-x hidden
        .top-text
          margin-top 30px
          margin-bottom 10px
          font-size 14px
        p.top-text, h3, form.error, textarea, .submit
          max-width calc(100vw - 40px)
        textarea
          width 100vw
          margin 0 auto
          font-size 14px