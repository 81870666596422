ul.menu-box
  vertical-align middle
  text-align center
  min-width 19%
  background-color #036f91
  margin 0
  padding 0
  li
    margin 3px 0
    display inline-block
    text-align center
    min-width 19%
    font-size 17px
    a
      display block
      position relative
      text-decoration none
      font-weight normal
      height 100%
      padding 10px 5px 5px 10px
      boredr-radius 0 2px 2px 0
      color #fff
      margin 3px 5px
      border none
      padding-left 0
      padding-right 0
      z-index 9
      &:hover
        color #68f6ff
    &.activate
      background-color #eee
  .sublink_small
    text-align start
    position relative
    .pinned_small
      vertical-align top
      text-align start
      width 19%
      margin 4px 0 0 5px
      font-size 15px
      a:before
        position absolute
        content "–"
        left: -12px;
  .line
    display unset
    position absolute
    top 50px
    left 0
    border-top 2px solid #0486ad
    width 100%
  li
    text-align center
    min-width 19%
    .tablet &
      border-bottom none!important

  .desktop &
    @media (min-width: 801px)
      text-align start
      background-color unset
      li.sublink
        float none
        margin 3px 0
      li
        text-align left
        width unset
        display block
        float none
        margin 3px 0
        &:hover
          background-color #eee
        a
          margin 3px 0
          color $link-text-color
          padding 10px 5px 5px 10px
          &:hover
            color $link-text-color-hover
      .pinned 
        border-bottom 1px solid #f2f2f2
      .pinned:last-child
        border-bottom 0
      .sublink_small
        li
          vertical-align center
          text-align start
          margin-left 19px
          width unset
          border-bottom none
          &:hover
            background-color #eee
      .line
        display none

    @media (max-width: 800px)
      li.activate
        background-color unset
      .pinned_small
        a
          margin 0 auto
      .sublink_small
        padding 7px 0

.tablet
  ul.menu-box
    li
      &.activate
        background-color unset
        a
          text-decoration underline
    .sublink_small
      display none

.mobile
  ul.menu-box
    text-align center
    margin 0 0 10px
    li
      display inline-block
      margin 0
      width 100%
      border-bottom 1px solid #6bbbdb
      text-align center
      padding 5px 0
      &.activate
        background-color #52acc6
      a
        padding-left 0
        //border-left none
        background transparent
        border-bottom transparent
        display block
        color #fff
        font-size 18px
        margin 0
        &:hover
          border-bottom transparent
    li.sublink
      float none
      margin-bottom 0
    .sublink_small
      display none
    .line
      display none


@media (max-width: 800px)
  .mobileShow,
  .smallMargin
    .widget
      padding 0 15px

.mobile
  #mobileShow
    background #036f91
    width 100%
    box-shadow 5px 5px 6px 0 #666
    display none

@media (max-width: 550px)
  .mobileShow,
  .smallMargin
    .widget
      padding 0 10px

.counts
  float right
  background-color #0085ac
  color #ffffff
  padding 1px
  font-size 12px
  min-width 30px
  border-radius 3px
  text-align center
  margin-right 5px
  line-height 18px

.countsActive
  background-color #00b8ed

.countsTransition
  transition background-color 1s

#mobileShow.down
  display block
  z-index 999
  position fixed