.text-logo
  display block
  background url(/images/sprite.png) -152px -204px
  width 140px
  height 34px
  font-size 1.6em
  color #fff
  font-weight bold
  margin 16px 0 0 0
  text-decoration none

  @media only screen and (max-width : 750px) and (orientation: portrait)
    width 98px
    font-size 1.2em
    background url(/images/sprite.png) 5px -206px

  span
    font-weight normal

  &:active,
  &:hover
    text-decoration none
    color #fff


.logo
  //float left
  //height 100%
  //margin-right 1.25em
  display inline-block
  //margin-right 7.95em
  //@media (max-width : 1140px)
  //  margin-right 9.15em
  //@media (max-width : 800px)
  //  margin-right 1.5em
  //@media (max-width: 675px)
  margin 0 auto
  height 100%


  a
    height 100%
    display inline-block
    vertical-align middle

    .logo-helper
      display inline-block
      height 100%
      vertical-align middle

    .large_screen
      vertical-align middle
      height 40px
      padding 11px 30px 0 10px
      //padding-top 11px
      //padding-left 11px
      .mobile &
        padding 15% 0 0 0
        width 110px
        height 50%
        //padding-left 10px