sprite2Crop_player(i,j,iFix=0,jFix=0)
  background url(/images/svg/sprite2Crop_player_1.svg) (-42px*i)+(iFix)px (-42*j)+(jFix)px no-repeat

$item-height = 65px
.module-layout
  &.error
    text-align center
    margin 80px 15px
    font-size 26px
    max-width 90%
    max-width calc(100% - 15px)//для поддержки до css3
    line-height 35px
    .mobile &
      width 90%
      font-size 24px
    .query
      font-weight bold
      font-size 27px
      overflow-wrap break-word
      word-wrap break-word

    .main_page
      font-size 13px
      line-height 25px
      margin-top 7px
      a
        color $link-text-color-hover
        text-decoration underline

  .songs
    //padding-right 20px
    //margin-top 40px
    a:hover
      .artist,
      .track
        text-decoration underline
    &.favoritePage
      overflow visible
      .item
        &:hover
          .handle
            content ''
            position absolute
            left 0
            background url("/images/sortable.png") 50% 50% no-repeat
            width 10px
            height 100%
            &:hover
              cursor move
        &.active
          .handle
            background url("/images/sortable_white.png") 50% 50% no-repeat

    //.item
    //  .actions
    //    > *
    //      display none
    //  &:hover
    //    .actions
    //      > *
    //        display inline-block
    //    .duration
    //      display none


    .item
      padding 3px 10px
      cursor pointer
      border-top 1px solid #f2f2f2
      position relative
      height $item-height
      width 98%
      -webkit-touch-callout none
      -webkit-user-select none
      -moz-user-select none
      -ms-user-select none
      user-select none
      &:nth-child(-n+4)
        .inPlaylists
          .dropdown-playlists
            top 10px!important
            ul
              max-height 116px
      &:nth-last-child(-n+7)
        .inPlaylists
          .dropdown-playlists
            top unset
            bottom 10px
      @media (max-width 1030px)
        &:hover,
        &:focus
          outline 0
          outline-offset 0
      .inPlaylists,
      .remove_playlist_track
        sprite2Crop_player(1,0,10)
        width 29px
        height @width
        margin-left 5px
        margin-right 20px
      .remove_playlist_track
        margin-right 10px
        margin-left 0
        background url("/images/svg/remove_playlist_track.svg") 50% 50% no-repeat
      &.no_file
        opacity 0.1
        filter unquote('alpha(opacity=10)')
        .actions
          display none
      &.load
        opacity 0.5
        filter unquote('alpha(opacity=50)')
        .play
          position relative
          background url(/images/spiner.gif) 50% 50% no-repeat !important
          border-radius 11px
      &.active
        .inPlaylists
          sprite2Crop_player(0,0)
        .remove_playlist_track
          background url("/images/svg/remove_playlist_track-white.svg") 50% 50% no-repeat
      &.error
        opacity 0.5
        filter unquote('alpha(opacity=50)')
        .play
          position relative
          background url(/images/error.png) 50% 50% no-repeat !important
          border-radius 11px

      .inPlaylists
        .dropdown-playlists
          position absolute
          width 100%
          max-width 290px
          max-height 311px
          height fit-content
          top 50px
          right 95px
          background #fff
          color #000
          box-sizing border-box
          box-shadow 0 3px 20px rgba(0, 0, 0, 0.25)
          border-radius 5px
          font-size 13px
          text-align left
          display none
          z-index 9
          @media (max-width 750px)
            right 45px
          @media (max-width 500px)
            right 20px
            &:nth-last-child(-n+7)
              bottom 50px
          ul
            margin-top 10px
            max-height 240px
            overflow-y auto
            scrollbar-color #5A5959 #FFFFFF
            scrollbar-width thin
            &:before
              content 'Добавить в плейлист'
              display block
              font-weight bold
              font-size 16px
              line-height 24px
              margin 0 20px
              padding-bottom 10px
              border-bottom 1px solid #E5E5E5
            &::-webkit-scrollbar
              width 5px
            &::-webkit-scrollbar-thumb
              background-color #5A5959
            li.playlist_item
              display block
              margin 0
              vertical-align unset
              font-weight bold
              font-size 14px
              line-height 40px
              padding-left 20px
              text-overflow ellipsis
              overflow hidden
              &:hover
                background #EFEFEF
              span
                display block
                max-width 180px
                text-overflow ellipsis
                overflow hidden
                white-space nowrap

        .create_playlist
          position relative
          font-weight bold
          font-size 14px
          line-height 30px
          margin 0 0 5px 20px
          padding 10px 0 5px 25px
          &:before
            content ''
            position absolute
            top 0
            left 0
            width 250px
            border-bottom 1px solid #E5E5E5
          span
            &:before
              content ''
              position absolute
              left 0
              top 15px
              width 20px
              height @width
              background url(/images/svg/create_Playlist.svg) 50% 50% no-repeat

      /* config */
      .itemLeft
        float left
      .itemRight
        float right

      .itemCenter
        display block
        margin 0 auto
        overflow hidden
        > *
          display block
      > * > *
        display inline-block
        line-height $item-height
        vertical-align middle
      h3
        margin 0
        font-size inherit
      ul
        margin 0
        padding 0

      /* styles */

      .playlistImg
        width 60px
        height @width
        overflow hidden
        vertical-align middle
        border-radius 4px
        img
          width 60px
          height @width

      ul.actions
        height $item-height

      li
        display inline-block
        vertical-align middle
        margin 0 5px

      .download
        margin 0

      .desc
        padding 0 5px
        margin-right 5px
        font-size 15px
        overflow hidden
        .artist
          font-weight bold
        .track
          opacity 0.7
        h3
          white-space nowrap
          overflow hidden
          max-width 100%
          text-overflow ellipsis
          font-weight normal
          a
            color inherit
            &:hover
              text-decoration underline


      .duration
        font-size 12px
        min-width 50px
        text-align center

      .play
        sprite2Crop_player(2,0,-11)
        height 22px
        width 22px
        &:active
        &:hover
          sprite2Crop_player(3,0,-1)

      .download
        .dl
          display inline-block
          vertical-align middle
          background url("/images/svg/dl_zvuk.svg") 50% 50% no-repeat
          margin-top -5px
          &:hover,
          &:active
            opacity .7
          .mobile &
            margin-top -3px

      &.active
        background $song-list-active-bg-color
        color $song-list-active-text-color
        .desc
          h3
            color $song-list-active-text-color

        .votes
          color $song-list-active-text-color

        .playOther
          background url("/images/svg/playOther_active.svg") 50% 50% no-repeat

        .dl
          background url("/images/svg/dl_zvuk_active.svg") 50% 50% no-repeat
          &:hover
            opacity .7

        .actions
          .play
            sprite2Crop_player(2,0,20)

        &.pause
          .actions
            .play
              sprite2Crop_player(4,0,8)


      &:hover
        color $link-text-color-hover
        &.error
          .play
            position relative
            background url(/images/refresh.png) 50% 50% no-repeat !important

      &.active
        color #fff
        &:hover
          color #fff

.addedSuccess,
.addedSuccessRadio,
.addedSuccessCollections
  position fixed
  bottom 65px
  left 45%
  width 100%
  max-width 330px
  text-align center
  padding 10px 30px
  background #0085ac
  border-radius 4px
  user-select none
  pointer-events none
  opacity 0
  @media (max-width 1030px)
    left 25%
  @media (max-width 500px)
    padding 10px 0
    max-width unset
    left 0
    text-overflow ellipsis
    overflow hidden
  @media (max-width 320px)
    padding 10px
    max-width 300px
  span
    color #fff
    font-weight 500
    font-size 16px
    line-height 20px!important
    @media (max-width 320px)
      max-width 310px
  &._now
    opacity 1
    z-index 9
    transition all 0.5s linear

.mobile
  .module-layout
    .songs
      .item
        .duration
        .votes
        .like
          display none
        h3
          padding-top 7px
          line-height 25px
          display inline-block

.tablet
  .module-layout
    .songs
      .item
        h3
          padding 0
          line-height 65px
          display inline-block

@media (max-width: 374px)
  .module-layout
    .songs
      .item
        .playlistImg
          display none
