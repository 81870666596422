//@import "mobile/index"
//@import "tablet/index"
//@import "mobile-tablet/index"

body.iOS

  .player-controls

    .download,
    .mute
      display none !important

  //.module-layout
  //  .songs
  //    .item
  //      padding 11px 18px 14px 45px


@media (max-width: 768px)
  body.mobile .box.collectionTop.blue .left 
    width: calc(80vw - 90px) !important;
    height: calc(80vw - 90px) !important;

  body.mobile .box.collectionTop.blue .left.tile>img, body.mobile .box.collectionTop.blue .left .audio_pl_grid_covers_wrap 
    width: calc(80vw - 100px) !important;
    height: calc(80vw - 100px) !important;

