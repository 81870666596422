.module-layout
  &.playlistPage
    padding-right 2px
    background none
    @media (max-width 1030px)
      padding-right 0
    .headPlaylist
      @media (max-width 500px)
        padding 0
    .mainPlaylist
      margin-top 10px
      background #FFFFFF
      box-shadow 0 1px 6px rgba(0, 0, 0, 0.25)
      border-radius 3px
      .search-box
        padding 12px 10px 0
      .searchPlaylist_popup
        padding 10px 0 0
        background #fff
        @media (max-width 1030px)
          margin 0
        .item
          .songName
            div
              line-height unset
      .addedTracks
        padding 0
        margin-top 15px
        margin-bottom 0
        li.item
          .songName
            color black
          .inPlaylists
            display none
          div
            line-height unset
      .searchPlaylist_popup,
      .addedTracks
        li.item
          display flex
          flex-flow row nowrap
          align-items center
          margin 0
          height 65px
          &:hover
            .songName
              color #0085ac
          &:last-child
            border-bottom none
          &.active
            .play
              background url(/images/svg/pause_zvooq_play-track.svg) 50% 50% no-repeat
            .songName
              color white
            &:hover
              .inPlaylists
                background url("/images/svg/add_inPlaylists-white.svg") 50% 50% no-repeat
          &.pause
            .play
              background url(/images/svg/play_zvooq_play-track.svg) 50% 50% no-repeat
          .play
            margin 0 20px
            @media (min-width 350px)
              margin 0 14px 0 6px
            @media (max-width 350px)
              margin 0 5px 0 0
          .songName
            flex 1
            color #000
            font-size 14px
            .artist
              font-weight bold
              &:after
                content ' — '
                margin 0 5px
            .track
              margin-right 5px
          .inPlaylists
            margin-right 15px
          .duration
            display none
      #user-playlist-search
        .inputSearch
          border 1px solid #C5C5C5
          height 35px
          margin-right 125px
          @media (max-width 500px)
            margin-right 30px
          input
            width 91%
            height 35px
            @media (max-width 500px)
              width 85%
          .dropdown
            button
              background-color #0180A4
          .reset
            right 10px !important
            .mobile &
              right 8px
      #sortable
        padding 0
        p
          color #000
          padding 20px 20px 0
          font-size 16px
          span
            font-size 20px
            color #0085ac
        &.songs
          padding-bottom 10px
          margin-bottom @padding-bottom
          @media (max-width 1030px)
            padding 0 10px 10px
          @media (max-width 800px)
            width 100%
          @media (max-width 500px)
            padding 0
        .item
          margin-right 0
          margin-left 0
          &:hover
            .handle
              content ''
              position absolute
              left 0
              background url("/images/sortable.png") 50% 50% no-repeat
              width 10px
              height 100%
              &:hover
                cursor move
          &.active
            .handle
              background url("/images/sortable_white.png") 50% 50% no-repeat
          @media (max-width 1030px)
            padding 3px 0
          @media (max-width 500px)
            width 100%

  &.favoritePage
    li
      display inline-block
      position relative
      width 100%
      max-width 100px
      padding 0
      margin 0 20px 10px 0
      background none
      text-align left
      font-size 12px
      font-weight bold
      cursor pointer
      @media (max-width 500px)
        margin 0 0 10px
      .cover
        width 100px
        height @width
        border-radius 8px
        position relative
        cursor pointer
        display block
        overflow hidden
        margin-bottom 5px
      .title
        color #000
        display block
        max-height 27px
        overflow hidden
      &:hover
        .title
          color #0085ac
  .newPlaylist
    .newPlaylist_cover
      display flex!important
      align-items center
      justify-content center
      background #E1E1E1
      border-radius 7px
      margin-bottom 5px
      width 100px
      height @width
      img
        width 50px
        height @width
  .audio_pl_grid
    top 0
    left 0
    width 100%
    height @width
    img
      width 100px
      height @width
      @media (max-width 1030px)
        width 100%
        height @width
    .newPlaylist_item
      background url("/images/album.svg") 50% 50% no-repeat
      border-radius 8px
      @media (max-width 1030px)
        width 100px
        height @width
    .audio_pl_grid_cover_once
      width 100%!important
      height @width
    .audio_pl_grid_cover
      position absolute
      background-size cover
      background-position 50%
      width 50%
      height @width
      .mobile &
        width 50%
        height @width
    .audio_pl_grid_cover_1
      left 50%
    .audio_pl_grid_cover_2
      top 50%
    .audio_pl_grid_cover_3
      left 50%
      top @left
  .headerPlaylist
    display flex
    @media (max-width 1030px)
      padding 0 10px
    @media (max-width 350px)
      padding 0 5px
    .playlist_track
      position relative
      width 100px
      height @width
      min-width @width
    .descriptionPlaylist
      margin-left 20px
      @media (max-width 750px)
        margin-left 10px
        width 100%
      .titlePlaylist
        display flex
        font-size 20px
        font-weight bold
        [contenteditable]
          -webkit-user-select text
          user-select text
          outline none
        div
          max-width 400px
          border-radius 4px
          border 1px solid transparent
          color #000
          overflow hidden
          @media (max-width 1030px)
            max-width 340px
          @media (max-width 500px)
            max-width 210px
          @media (max-width 350px)
            max-width 190px
        span
          font-weight bold
          font-size 20px
          line-height 22px
        .edit_playlist
          margin-left 10px
          margin-right 10px
          width 24px
          height @width
          cursor pointer
          @media (max-width 500px)
            display none
        .edit_playlist-done
        .edit_playlist-err
          display none
        svg
          width 24px
          height @width
        path
          stroke-dasharray 99.47578430175781
          stroke-dashoffset -99.47578430175781
          fill transparent
        svg.animate path
          fill #0085ac
          opacity 1
        @keyframes draw
          0%
            opacity 1
            stroke-dashoffset -99.47578430175781
            fill transparent
            transform translateY(0)
          35%
            stroke-dashoffset 0
            fill transparent
          60%
            fill $palette1
            opacity 1
            transform translateY(0)
          100%
            fill $palette1
            stroke-dashoffset 0
            opacity 0
            transform translateY(-10px)
      .buttonsPlaylist
        display flex
        margin-top 10px
        margin-bottom 15px
        @media (max-width 350px)
          margin-top 10px
          margin-bottom 10px
        .button.listen
          min-width 126px
          padding 0 19px
          margin 0
          line-height 35px
          border-radius 7px
          background #0180A4
          border none
          color white
          &:before
            content ''
            display inline-block
            position relative
            left 0
            top 4px
            margin-right 10px
            background url(/images/svg/playAlbum.svg)
            height 16px
            width 15px
          &.disabled
            opacity .4
            user-select none
            cursor default
          @media (max-width 750px)
            padding 0 10px
        .remove_playlist
          background url("/images/svg/remove_playlist.svg") 50% 50% no-repeat
          background-color #0180a4
          width 36px
          height @width
          border-radius 5px
          margin-left 10px
          cursor pointer
          @media (max-width 1030px)
            margin-left 10px
      .bottomPlaylist
        display flex
        justify-content space-between
        .duration
          font-size 14px
          line-height 16px
          color #000
        .emptyTracks
          margin 0
          p
            font-weight 500
            font-size 14px
            line-height 16px
            color #454545
            @media (max-width 1030px)
              line-height 23px
        @media (max-width 500px)
          .shared_buttons
            .show_share
              margin 0 0 10px
              padding 0
              background-position 50%
              min-width 30px
              span
                display none
        @media (max-width 350px)
          display block
          .shared_buttons
            margin-top 10px
    .shared_buttons
      margin-left auto

  .modal_removePlaylist
    display none
    position fixed
    z-index 1001
    left 0
    top 0
    width 100%
    height 100%
    overflow auto
    background-color rgba(0,0,0,0.4)
    @media (max-width 500px)
      padding 0
    .modal-content
      background #fff
      box-shadow 0 3px 20px rgba(0, 0, 0, 0.25)
      margin 15% auto
      padding 20px 30px 5px
      border-radius 5px
      height 186px
      width 50%
      max-width 532px - 20px - @padding
      @media (max-width 1030px)
        width 80%
      @media (max-width 750px)
        padding 0
        margin 40% auto
        height unset
        width 100%
    .close
      float right
      background url("/images/svg/close.svg") 50% 50% no-repeat
      width 20px
      height @width
      cursor pointer
      @media (max-width 750px)
        padding 0 10px 10px 0
    p
      display flex
      flex-direction column
      margin 0
      padding 10px 0 0
      line-height 40px
      color #222222
      @media (max-width 750px)
        padding 40px 10px 0
        line-height 30px
      span
        &:first-child
          font-weight bold
          font-size 20px
          @media (max-width 500px)
            font-size 18px
        &:last-child
          font-weight 500
          font-size 16px
          @media (max-width 500px)
            font-size 14px
    .modal_buttons
      display flex
      justify-content flex-start
      margin 30px 0
      @media (max-width 750px)
        justify-content center
        padding 30px 10px
        margin 0
      @media (max-width 500px)
        justify-content start
      > *
        width 85px
        height 36px
        border-radius 7px
        box-sizing border-box
        font-weight bold
        font-size 14px
        line-height 35px
        text-align center
        cursor pointer
      .button_cancel
        color #0185AA
        margin-right 30px
      .button_delete
        background #0180A4
        color #fff