$dark_bg = #323232;
$white = #fff;
$black = #151515;
$dark_btn = #00B1E3;
$border = #454545;
$hover = #5ADBFF;

sprite2Crop_dark(i,j,iFix=0,jFix=0)
  background url(/images/sprite_darkTheme.svg) (-42px*i)+(iFix)px (-42*j)+(jFix)px no-repeat

.darkTheme
  display flex
  align-items center
  font-weight 500
  font-size 16px
  line-height 30px
  text-align right
  margin-right 20px
  color #000
  cursor pointer
  @media (max-width 1030px)
    margin-right 0
  .mobile_dark
    display flex
  .switch_btn
    display inline-block
    width 32px
    height 16px
    border-radius 12px
    margin-right 5px
    background #0180A4
    z-index 0
    padding 0
    border none
    cursor pointer
    position relative
    transition-duration 300ms
    &:after
      content ""
      height 14px
      width @height
      border-radius 17px
      background #fff
      top 1px
      left @top
      transition-duration 300ms
      position absolute
      z-index 1
    &.switch_on
      background $dark_btn
      &:after
        left 17px

body.dark_theme
  background $black
  color $white
  h1,h2,h3,h4,.title
    color $white
  .fixedBottom
    .player-controls
      .container
        background #0085ac
  .backShadows
    box-shadow none
    .module-layout
      .songs
        padding-right 20px
        .item
          border-top 1px solid $border
  .backShadows,
  .burger_menu
    background $dark_bg
    a
      color $white!important
  .header
    .myMusic
      span,a
        color $white
      @media (max-width 1030px)
        span
          &:nth-child(-n+2)
            border-bottom 1px solid #4E4E4E
        .dropdownUserMenu
          background $dark_bg
          .darkTheme
            .mobile_dark
              &:after
                content 'Светлая тема'
    .search-box
      form
        background inherit
        .inputSearch
          background $black
          border none
          margin-right 125px
          @media (max-width 750px)
            margin-right 0
          input
            background $black
            &::-webkit-input-placeholder {color:$white;opacity:.6;}
            &::-moz-placeholder          {color:$white;opacity:.6;}/* Firefox 19+ */
            &:-moz-placeholder           {color:$white;opacity:.6;}/* Firefox 18- */
            &:-ms-input-placeholder      {color:$white;opacity:.6;}
            color $white
          .dropdown
            button
              background-color $dark_btn
          .dropdown-menu
            background-color $dark_bg
            box-shadow unset
            top 37px
            li
              color $white
            .name
              &:before
                sprite2Crop_dark(3,3,-4,-7)
            &.name_focus
              li.name
                color $hover
            .artist
              &:before
                sprite2Crop_dark(4,3,-5,-9)
            &.artist_focus
              li.artist
                color $hover
            .byRadio
              &:before
                sprite2Crop_dark(0,2,-5,-9)
            &.byRadio_focus
              li.byRadio
                color $hover
            .collections
              &:before
                sprite2Crop_dark(8,3,-3,-9)
            &.collections_focus
              li.collections
                color $hover
          select
            color $white

  /*general container*/
  .container
    background $black
    .content
      background inherit
      .desktop
        background $black
      .topBlock
        border-bottom none
        span
          color $white
      .topLine
        &.favoritePage
          .darkTheme
            span
              color #fff

      /*filter*/
      #globalChoice
        background-color $dark_bg
        .clear
          color $white
        .list
          .choice_active
            background $dark_btn
          .choice,
          .globalTitle
            color $white
          .more
            color $hover
        .apply
          background-color $dark_btn
      .kinetic
        .cover
          box-shadow none
      .desktopMenuShow
        li
          border-bottom 1px solid #4e4e4e
          a
            color $white
          &.activate
            a
              color $dark_btn
      .playlistPage
        .module-layout
          background inherit
      .headlineLine
        button.filter
          sprite2Crop_dark(0,5)
          &:hover
            sprite2Crop_dark(1,5)
        .sort
          > *
            background-color $hover
          a,span
            &.activated_sort
              background-color $dark_btn
          span
            color $white
      .module-layout
        .smallMargin
          .box.widget
            padding-bottom 25px
        .collectionButtonLine
          .button.favorite
            color $white
            &.activated
              background $dark_btn
              border 2px solid $dark_btn
        .box
          background $dark_bg
          border-radius 4px
          @media (max-width 750px)
            background unset
          &.widget
            margin-top 5px
            padding-top 8px
            .flipping
              .flip
                background-color #1797BB
                border 4px solid #323232
                &.active
                  background-color #323232
                  border 4px solid #1797BB
          &.collectionTop
            h1
              color #FFFFFF
          .topPageDescription
            a
              color $dark_btn
          &.genresTop.blue
            .main h1
              color #FFFFFF
        &.playlistPage
          .mainPlaylist
            background $dark_bg
            #sortable
              .error
                color $white
            #user-playlist-search
              .inputSearch
                background $black
                border none
                height 37px
                border-radius 4px
                input
                  color $white
                  background $black
            .searchPlaylist_popup,
            .addedTracks
              background $dark_bg
              li.item
                .songName
                  color $white
                .play
                  sprite2Crop_dark(1,2)
                  &.active
                    .play
                      sprite2Crop_dark(2,2)
                    &.pause
                      .play
                        sprite2Crop_dark(1,2)
            .remove_playlist_track
              background url("/images/svg/remove_playlist_track_dark.svg") 50% 50% no-repeat
          .titlePlaylist
            div
              color $white
          .bottomPlaylist
            p
              color $white
          .duration
            color $white

        /*songItem*/
        .songs
          background $dark_bg
          .item
            border-top 1px solid $border
            border-radius 4px
            @media (min-width 750px)
              &:hover,
              &.active
                background none
                .desc
                  a
                    color $hover
                  .track
                    opacity 1
            .desc
              h3:after
                border-bottom 1px dashed #585858
            .inPlaylists
              sprite2Crop_dark(6,5)
              &:hover
                sprite2Crop_dark(6,6)
              .playlist_item
                &:hover
                  background $dark_bg
              .dropdown-playlists
                background $black
                box-shadow 0 3px 20px rgba(0, 0, 0, 0.5)
                ul
                  scrollbar-color #5A5959 #323232
                  &:before
                    border-bottom 1px solid #454545
                    color $white
                  li
                    color $white
              .create_playlist
                color $white
                &:before
                  border-bottom 1px solid #454545
                  background url(/images/svg/create_Playlist_dark.svg) 50% 50% no-repeat
            .dl
              sprite2Crop_dark(6,0,-5,-2)
              &:hover
                sprite2Crop_dark(6,1,-5,-1)
            .favoriteIco
              background url(/images/svg/like_dark.svg) 50% 50% no-repeat
              &.activated
                background url(/images/svg/like_dark-active.svg) 50% 50% no-repeat
            .play
              sprite2Crop_dark(1,2,-3,-2)
            &.active
              .play
                sprite2Crop_dark(2,2,-4,-2)
              &.pause
                .play
                  sprite2Crop_dark(1,2,-3,-2)
        .buttonsPlaylist
          .button.listen
            background $dark_btn
          .remove_playlist
            background-color $dark_btn
        .modal_removePlaylist
          box-shadow 0 3px 20px rgba(0, 0, 0, 0.5)
          .modal-content
            background $dark_bg
            p
              color $white
            .button_delete
              background $dark_btn
            .button_cancel
              color $hover
        .kinetic
          .title
            color $white
        .CategoryWrapper
          a
            color $white
        .plateItems
          .title
            color $white
        &.favoritePage
          .title
            color $white
        .subTitle
          a
            color $white
        .addedSuccess,
        .addedSuccessRadio,
        .addedSuccessCollections
          background $black
          span
            color $white

      /*main span center desktop*/
      .span
        &.desktop
          .module-layout
            .songs
              background $dark_bg
              .item
                border-top 1px solid $border
                border-radius 4px
                @media (min-width 750px)
                  &:hover,
                  &.active
                    background none
                    .desc
                      a
                        color $hover
                      .track
                        opacity 1

        /*main sidebar desktop*/
        &.desktop-sidebar
          .mobileShow
            .burger_menu
              box-shadow none
              .pinned
                border-bottom 1px solid $border
              li
                &:hover,
                &.activate
                  background-color unset
                  a
                    color $hover!important
          span
            color $white
    .footer
      a
        color $white
        &:hover
          color $hover
      .footerMenu
        li
          border-bottom 1px solid $border
          &.pinned_small
            border-bottom none
          &:hover,
          &.activate
            background-color unset
            a
              color $hover!important
      .footerContent
        .title
          color $white
          &:hover
            color $hover
  .feedback-modal
    background #323232
