.pagination
  padding 0 20px
  margin-bottom 1.7em

  li
    inline-block()
    margin-right 2px

    span,
    a
      margin-bottom 5px

    span
      padding 5px 11px 5px

    a
      border-radius(4px)
      inline-block()
      text-decoration none
      padding 5px 11px 5px
      background $pagination-item-bg-color
      color $pagination-item-text-color
      opacity 0.8
      filter unquote('alpha(opacity=80)')

      &.active,
      &:hover
        background $pagination-active-bg-color
        color $pagination-active-text-color
        opacity 1
        filter unquote('alpha(opacity=100)')