.footer
  padding 5px 15px 10px 5px
  clear both
  @media (max-width : 800px)
    border-top 1px solid #ddd
    padding 5px 20px 10px
  .backShadows
     box-shadow 0 0 2px 1px #eeeeee
     border-radius 4px
     padding 10px
     h3
       max-width 140px
       margin 5px 0
     @media (max-width : 800px)
       display none
  .footerLeft
    float left
    width 250px
    font-size 16px
    font-weight 400
    color black
    li
      border-bottom 1px solid #f2f2f2
      vertical-align middle
      &:last-child
        border-bottom none
      &:hover
        background-color #eee
        a
          color #0085ac
      a
        padding 10px 5px 10px 10px
        width 100%
      &.activate
        background-color #eee

  .footerRight
    padding 0 5px 5px 5px
    margin-left 311px
  .footerLeft
  .footerRight
    box-sizing border-box
  a
    display table

  h3,
  h4,
  h5
    margin-top 0

  p
    margin 10px 10px
    float left
    width 98%

    span
      display inline-block
      @media (min-width : 500px)
        &:last-child
          float right
      a
        display inline-block


.footerContent
  display none
  @media (min-width : 800px)
    display block
    overflow hidden
    font-size 14px
    padding 0 10px

  .footerCollections
    margin-top 10px
    //float left
    width 100%
    @media (max-width 1000px)
      width 100%
    .listItems
      column-count 4
      @media (max-width : 1100px)
        column-count 2
      .listItemsItem
        margin-bottom 30px
        max-height 180px
        line-height 16px
      .widgetLink
        display inline-block
        color #000
        margin 12px 0 6px
        font-size 15px
        font-weight 700
        &:hover
          color #03aeef
          cursor pointer
      li
        vertical-align middle
        font-size 13px
      .collectionImg
        float left
        margin-right 10px
        img
          width 40px
          height 40px
          margin-top 5px
          border-radius 4px
      span
        color #000
        font-size 1em
        display table-cell
        vertical-align middle
        text-overflow ellipsis
        overflow: hidden
        display: -webkit-box
        -webkit-line-clamp 3
        -webkit-box-orient vertical

        &:hover
          color #03aeef
          cursor pointer

  .footerMenu
    float right
    margin 20px 0 0
    @media (max-width 1000px)
      float left
      margin-bottom 10px
    a
      text-decoration underline
      &:hover
        color #03aeef
        cursor pointer