.slick-slider
  position relative
  display block
  box-sizing border-box
  overflow hidden
  -webkit-touch-callout none
  -webkit-user-select none
  -khtml-user-select none
  -moz-user-select none
  -ms-user-select none
  user-select none
  -ms-touch-action pan-y
  touch-action pan-y
  -webkit-tap-highlight-color transparent
.slick-list
  position relative
  overflow hidden
  display block
  margin 0
  padding 0
  &:focus
    outline none
  &.dragging
    cursor hand
.slick-slider .slick-track,
.slick-slider .slick-list
  -webkit-transform translate3d(0, 0, 0)
  -moz-transform translate3d(0, 0, 0)
  -ms-transform translate3d(0, 0, 0)
  -o-transform translate3d(0, 0, 0)
  transform translate3d(0, 0, 0)
.slick-track
  position relative
  left 0
  top 0
  display block
  margin-left auto
  margin-right auto
  &:before,
  &:after
    content ''
    display table
  &:after
    clear both
  .slick-loading &
    visibility hidden
.slick-slide
  float left
  height 100%
  min-height 1px
  display none
  img
    display block
    width 100%
    height @width
  &.slick-loading img
    display none
  &.dragging img
    pointer-events none
  .slick-initialized &
    display block
  .slick-loading &
    visibility hidden
  .slick-vertical &
    display block
    height auto
    border 1px solid transparent
.slick-arrow.slick-hidden
  display none

/* Arrows */
.slick-prev,
.slick-next
  position absolute
  display block
  height 13px
  width 10px
  line-height 0
  font-size 0
  cursor pointer
  background transparent
  color transparent
  bottom 0
  -webkit-transform translate(0, -50%)
  -ms-transform translate(0, -50%)
  transform translate(0, -50%)
  padding 0
  border none
  outline none
  &:before
    font-size 11px
    line-height 1
    color #000
    -webkit-font-smoothing antialiased
    -moz-osx-font-smoothing grayscale
.slick-prev
  left 0
  &:before
    content '❮'
.slick-next
  right 0
  &:before
    content '❯'

/* Dots */
.slick-dots
  position absolute
  bottom 0
  list-style none
  display block
  text-align center
  padding 0
  margin 0
  width 100%
  li
    position relative
    display inline-block
    height 16px
    width 16px
    margin 0 3.5px
    padding 0
    overflow visible !important
    cursor pointer
    button
      border 0
      background transparent
      display block
      height 16px
      width 16px
      outline none
      line-height 0
      font-size 0
      color transparent
      padding 5px
      cursor pointer
      &:before
        content ''
        position absolute
        top 0
        left 0
        width 8px
        height 8px
        background-color #1797bb
        border-radius 100% 100%
        border 4px solid #fff
        margin-right 4px
        transition opacity .6s ease
        box-sizing content-box
        cursor pointer
    &.slick-active button:before
      background-color #fff
      border 4px solid #1797bb


.box.miniSlider
  padding 0
  margin-bottom 15px
  margin-top 20px
  .containerWidget
    width 272px
    min-height 393px
    padding 10px
    border-radius 5px
    margin 0 auto
    box-sizing border-box
    position relative
    &:before
      content ''
      position absolute
      top 0
      left 0
      width 272px
      height 363px
      border-radius 5px
      background linear-gradient(210.08deg, #43D5FF 0.73%, #007799 100%)
    .widgetHeader
      position relative
      h3
        color #fff
        font-size 17px
        &:hover
          color: #C8F3FF
    .slider
      position relative
      overflow hidden
      width 252px
      padding-bottom 47px
      display flex
      flex-wrap nowrap
      &__item
        flex 0 0 100%
        max-width 100%
        .audio_pl_grid_covers_wrap
        .audio_pl_grid
          height 252px
          width 252px
          img
            width 126px
            height 126px
        .title
          color #FFFFFF
          font-size 14px
          margin 8px 3px 0
          height 36px
          overflow hidden
          &:hover
            color: #C8F3FF
      .slick-dots
        margin 1px 10px
        width 230px
        bottom 4px
        .dark_theme &
          li
            button:before
              border 4px solid #151515
              background-color #1797bb
            &.slick-active button:before
              background-color #151515
              border 4px solid #1797bb
      .slick-prev
          background-image url("/images/svg/back.svg")
          &:before
            left -5px
      .slick-next
          background-image url("/images/svg/next.svg")
          &:before
            right -5px
      .slick-prev,
      .slick-next
        background-repeat no-repeat
        bottom -1px
        height 14px
        width 10px
        &:before
          font-size 0
          height 30px
          width 30px
          bottom -7px
          position absolute
          cursor pointer
        .dark_theme &
          background-image none
          &:before
            height 21px
            font-size 14px
            color #DDD


.box.sliderSLick
  padding 10px 0
  &.widget
    margin-bottom -6px
    .widgetHeader
      margin 0 19px 0 15px
    .moreButton
      margin-bottom 4px
  .sliderSLickMain
    display flex
    justify-content space-between
    margin-bottom 0
    padding 0 0 35px 15px
    overflow hidden
    li.collectionGrid
      margin-bottom 0
      margin-right 15px
      list-style none
    .slick-dots
      right 0
      .dark_theme &
        li
          button:before
            border 4px solid #323232
            background-color #1797bb
          &.slick-active button:before
            background-color #323232
            border 4px solid #1797bb
    .slick-slider
      padding-bottom 20px
    .slick-list
      width 100%
      z-index 10
    .slick-prev
      left 5px
      &:before
        margin-right 4px
    .slick-next
      right 5px
      &:before
        margin-left 4px
    .slick-prev,
    .slick-next
      bottom 51%
      z-index 20
      background #FFFFFF
      box-shadow 0 2px 10px rgba(0, 0, 0, 0.25)
      width 40px
      height 40px
      border-radius 100%
      box-sizing content-box
      &:before
        color #333333
        font-size 14px